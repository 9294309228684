<template>
    <nav-bar>
        <div class="text-center pb-4 mx-2">
            <document-sender></document-sender>
        </div>
        <nav class="nav-main">
            <ul class="list-unstyled text-center">
                <li>
                    <router-link to="/admin" class="m-1">
                        <span class="icon pi pi-chart-bar mr-3"></span>
                        <span>Oversigt</span>
                    </router-link>
                </li>
                <li>
                    <router-link :to="{name:'AdminMembers'}" class="nav-main-members m-1">
                        <span class="icon pi pi-users mr-3"></span>
                        <span>Kontakter</span>
                    </router-link>
                </li>
                <li>
                    <router-link :to="{name:'AdminTemplates'}" class="nav-main-templates m-1">
                        <span class="icon pi pi-file-o mr-3"></span>
                        <span>Skabeloner</span>
                    </router-link>
                </li>
                <li>
                    <router-link :to="{name:'AdminConsents'}" class="nav-main-consents m-1">
                        <span class="icon pi pi-check-square mr-3"></span>
                        <span>Samtykker</span>
                    </router-link>
                </li>
            </ul>
            <hr class="my-4" />
            <ul class="list-unstyled text-center">
                <li>
                    <router-link :to="{name:'AdminRecycleBin'}" class="mx-1">
                        <span class="icon pi pi-trash mr-3"></span>
                        <span>Papirkurv</span>
                    </router-link>
                </li>
                <li>
                    <router-link :to="{name:'AdminArchive'}" class="mx-1">
                        <span class="icon pi pi-inbox mr-3"></span>
                        <span>Arkiv</span>
                    </router-link>
                </li>
                <li>
                    <router-link :to="{name:'AdminHelp'}" class="mx-1">
                        <span class="icon pi pi-question-circle mr-3"></span>
                        <span>Hjælp</span>
                    </router-link>
                </li>
            </ul>
            <hr class="my-4" />
            <ul class="list-unstyled text-center">
                <li>
                    <router-link :to="{name:'AdminSettings'}" class="mx-1">
                        <span class="icon pi pi-cog mr-3"></span>
                        <span>Indstillinger</span>
                    </router-link>
                </li>
                <li>
                    <router-link :to="{name:'AdminUsers'}" class="mx-1">
                        <span class="icon pi pi-users mr-3"></span>
                        <span>Brugere</span>
                    </router-link>
                </li>
                <li>
                    <router-link :to="{name:'AdminLog'}" class="mx-1">
                        <span class="icon pi pi-list mr-3"></span>
                        <span>Hændelseslog</span>
                    </router-link>
                </li>
                <li>
                    <router-link :to="{name:'AdminIntegrations'}" class="mx-1">
                        <span class="icon pi pi-bolt mr-3"></span>
                        <span>Integrationer</span>
                    </router-link>
                </li>
            </ul>
        </nav>
    </nav-bar>
    <div class="content">
        <main class="main">
            <router-view />
        </main>
    </div>
</template>
