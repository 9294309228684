<template>
    <div class="document-student-grant-form" ref="top">
        <div v-if="vm.step !== 'start'" class="text-center mb-3">
            <div class="steps relative mb-5 hidden md:inline-flex">
                <div class="step" :class="{'active': vm.step === 'student'}">
                    <div class="step-number">1</div>
                    <div class="step-description">Udfyldes af eleven</div>
                </div>
                <div class="step" :class="{'active': vm.step === 'parents'}">
                    <div class="step-number">2</div>
                    <div class="step-description">Udfyldes af forældre</div>
                </div>
                <div class="step">
                    <div class="step-number">3</div>
                    <div class="step-description">Godkend</div>
                </div>
            </div>
        </div>
        <h2 class="text-center">Ansøgning om statslig elevstøtte</h2>
        <p class="text-center" v-if="vm.step === 'start'">
            Anvendes ved ansøgning om statslig elevstøtte til deltagelse i kursus på en efterskole
        </p>
        <!-- INTRO -->
        <div class="grid mt-6" v-show="vm.step === 'start'">
            <div class="col-12 md:col-6 flex flex-column">
                <h4 class="text-center">Sted</h4>
                <div class="box h-full flex justify-content-between">
                    <div style="line-height:1.3">
                        <div>{{metaData.customerName}}</div>
                        <div>{{metaData.customerStreet}}</div>
                        <div>{{metaData.customerZip}} {{metaData.customerCity}}</div>
                    </div>
                    <div>
                        <img :src="metaData.customerLogo" style="max-height: 80px;max-width: 80px;">
                    </div>
                </div>
            </div>
            <div class="col-12 md:col-6 flex flex-column">
                <h4 class="text-center">Varighed</h4>
                <div class="box h-full">

                    <div style="line-height:1.3">
                        <div class="flex justify-content-between">Kursets startdato: <strong>{{$filters.formatDateSimple(metaData.courseStartDate)}}</strong></div>
                        <div class="flex justify-content-between">Kursets slutdato: <strong>{{$filters.formatDateSimple(metaData.courseEndDate)}}</strong></div>
                        <div class="flex justify-content-between">Antal uger: <strong>{{metaData.courseLengthInWeeks}}</strong></div>
                        <div class="flex justify-content-between">Indkomstår: <strong>{{metaData.incomeYear}}</strong></div>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-6 pt-6 text-center">
                <Button icon="pi pi-angle-right" iconPos="right" label="Start" class="p-button-success" style="min-width:200px" @click="fn.goToStudentDataStep()" />
            </div>
        </div>
        <!-- STUDENT -->
        <div v-show="vm.step === 'student'">
            <form novalidate autocomplete="off">
                <input autocomplete="false" name="hidden" type="text" style="display:none;">
                <div class="mt-6 mb-1 text-center">
                    <h4 class="highlight">Nedenstående udfyldes af <span class="underline">eleven</span>:</h4>
                </div>

                <div class="box h-full grid">
                    <div class="col-12">
                        <InlineMessage severity="warn w-full" v-if="vm.studentGrantData.studentGrantDataInformation.studentPersonalIdEncrypted && vm.studentGrantData.studentGrantDataInformation.studentPersonalIdEncrypted.indexOf('x') === -1 && !$filters.isCprValid(vm.studentGrantData.studentGrantDataInformation.studentPersonalIdEncrypted)">
                            Vi kan ikke validere dit personnummer. Venligst sikre at det er indtastet korrekt?
                        </InlineMessage>
                    </div>
                    <div class="col-12 md:col-4">
                        <span class="p-float-label">
                            <InputMask v-if="vm.personalIdMask" id="inputname" type="text" class="p-100" :mask="vm.personalIdMask" slotChar="x" v-model="vm.studentGrantData.studentGrantDataInformation.studentPersonalIdEncrypted" :class="{'p-invalid':v$students.studentGrantData.studentGrantDataInformation.studentPersonalIdEncrypted.$invalid && vm.validateStudent}" required />
                            <label for="inputname">Elevens personnummer</label>
                        </span>
                        <small v-if="(v$students.studentGrantData.studentGrantDataInformation.studentPersonalIdEncrypted.$invalid && vm.validateStudent) || v$students.studentGrantData.studentGrantDataInformation.studentPersonalIdEncrypted.$pending.$response" class="p-error">CPR nummer skal udfyldes</small>
                    </div>
                    <div class="col-12 md:col-8">
                        <span class="p-float-label">
                            <InputText id="inputname" type="text" class="p-100" v-model="vm.studentGrantData.studentGrantDataInformation.studentName" :class="{'p-invalid':v$students.studentGrantData.studentGrantDataInformation.studentName.$invalid && vm.validateStudent}" autocomplete="off" role="presentation" required />
                            <label for="inputname">Elevens fulde navn</label>
                        </span>
                        <small v-if="(v$students.studentGrantData.studentGrantDataInformation.studentName.$invalid && vm.validateStudent) || v$students.studentGrantData.studentGrantDataInformation.studentName.$pending.$response" class="p-error">Navn skal udfyldes</small>
                    </div>
                    <div class="col-12">
                        <div class="autocomplete-container">
                            <span class="p-float-label">
                                <InputText id="dawa-autocomplete-input" type="text" class="p-100" v-model="vm.studentGrantData.studentGrantDataInformation.dawa" :class="{'p-invalid':(v$students.studentGrantData.studentGrantDataInformation.dawa.$invalid || v$students.studentGrantData.studentGrantDataInformation.address.$invalid) && vm.validateStudent}" :disabled="vm.enterAddressManually" required />
                                <label for="dawa-autocomplete-input" :class="{'opacity-50': vm.enterAddressManually} ">Adresse</label>
                            </span>
                            <small v-if="((v$students.studentGrantData.studentGrantDataInformation.dawa.$invalid || v$students.studentGrantData.studentGrantDataInformation.address.$invalid) && vm.validateStudent) || (v$students.studentGrantData.studentGrantDataInformation.dawa.$pending.$response || v$students.studentGrantData.studentGrantDataInformation.address.$pending.$response)" class="p-error">Adresse skal udfyldes</small>
                        </div>
                        <div class="field-checkbox align-items-start justify-content-end mt-2">
                            <label for="enterAddressManually" style="line-height:1.2"><small>Kan du ikke finde din adresse?</small></label>
                            <Checkbox inputId="enterAddressManually" class="ml-2" :binary="true" v-model="vm.enterAddressManually" />
                        </div>
                        <div v-if="vm.enterAddressManually" class="grid mb-5">
                            <div class="col-12">
                                <span class="p-float-label">
                                    <InputText id="inputManualAddress" type="text" class="p-100" v-model="vm.studentGrantData.studentGrantDataInformation.address" :class="{'p-invalid':v$students.studentGrantData.studentGrantDataInformation.address.$invalid && vm.validateStudent}" autocomplete="off" role="presentation" required />
                                    <label for="inputManualAddress">Adresse</label>
                                </span>
                                <small v-if="(v$students.studentGrantData.studentGrantDataInformation.address.$invalid && vm.validateStudent) || v$students.studentGrantData.studentGrantDataInformation.address.$pending.$response" class="p-error">Adresse skal udfyldes</small>
                            </div>
                            <div class="col-3">
                                <span class="p-float-label">
                                    <InputText id="inputManualZip" type="text" class="p-100" v-model="vm.studentGrantData.studentGrantDataInformation.zip" :class="{'p-invalid':v$students.studentGrantData.studentGrantDataInformation.zip.$invalid && vm.validateStudent}" autocomplete="off" role="presentation" required />
                                    <label for="inputManualZip">Postnr</label>
                                </span>
                                <small v-if="(v$students.studentGrantData.studentGrantDataInformation.zip.$invalid && vm.validateStudent) || v$students.studentGrantData.studentGrantDataInformation.zip.$pending.$response" class="p-error">Postnummer skal udfyldes</small>
                            </div>
                            <div class="col-9">
                                <span class="p-float-label">
                                    <InputText id="inputManualCity" type="text" class="p-100" v-model="vm.studentGrantData.studentGrantDataInformation.city" :class="{'p-invalid':v$students.studentGrantData.studentGrantDataInformation.city.$invalid && vm.validateStudent}" autocomplete="off" role="presentation" required />
                                    <label for="inputManualCity">By</label>
                                </span>
                                <small v-if="(v$students.studentGrantData.studentGrantDataInformation.city.$invalid && vm.validateStudent) || v$students.studentGrantData.studentGrantDataInformation.city.$pending.$response" class="p-error">By skal udfyldes</small>
                            </div>
                            <div class="col-12">
                                <span class="p-float-label">
                                    <InputText id="inputManualCommuneName" type="text" class="p-100" v-model="vm.studentGrantData.studentGrantDataInformation.communeName" :class="{'p-invalid':v$students.studentGrantData.studentGrantDataInformation.communeName.$invalid && vm.validateStudent}" autocomplete="off" role="presentation" required />
                                    <label for="inputManualCommuneName">Kommune</label>
                                </span>
                                <small v-if="(v$students.studentGrantData.studentGrantDataInformation.communeName.$invalid && vm.validateStudent) || v$students.studentGrantData.studentGrantDataInformation.communeName.$pending.$response" class="p-error">Kommunen skal udfyldes</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <span class="p-float-label">
                            <InputText id="inputemail" type="text" class="p-100" v-model="vm.studentGrantData.studentGrantDataInformation.email" :class="{'p-invalid':v$students.studentGrantData.studentGrantDataInformation.email.$invalid && vm.validateStudent}" autocomplete="off" role="presentation" required />
                            <label for="inputemail">Elevens e-mail (valgfrit)</label>
                        </span>
                        <small v-if="v$students.studentGrantData.studentGrantDataInformation.email.email.$invalid && vm.validateStudent" class="p-error">
                            Den indtastede e-mail er ikke gyldig
                        </small>
                        <!--<small v-else-if="(v$students.studentGrantData.studentGrantDataInformation.email.$invalid && vm.validateStudent) || v$students.studentGrantData.studentGrantDataInformation.email.$pending.$response" class="p-error">E-mail skal udfyldes</small>-->
                    </div>
                    <div class="col-12">
                        <div>Hvilken type elev?</div>
                        <div style="margin-top: -0.5rem"><small class="font-italic">Kostelever bor på skolen, mens dagelever kommer til skolen hver dag</small></div>
                        <div class="flex mt-1">
                            <div class="field-radiobutton mr-3 mb-0">
                                <RadioButton inputId="student-regular" name="student-type" value="1" v-model="vm.studentGrantData.studentGrantDataInformation.studentRegular" :class="{'p-invalid':v$students.studentGrantData.studentGrantDataInformation.studentRegular.$invalid && vm.validateStudent}" required />
                                <label for="student-regular">Kostelev</label>
                            </div>
                            <div class="field-radiobutton mr-3 mb-0">
                                <RadioButton inputId="student-day" name="student-type" value="0" v-model="vm.studentGrantData.studentGrantDataInformation.studentRegular" :class="{'p-invalid':v$students.studentGrantData.studentGrantDataInformation.studentRegular.$invalid && vm.validateStudent}" required />
                                <label for="student-day">Dagelev</label>
                            </div>
                        </div>
                        <small v-if="(v$students.studentGrantData.studentGrantDataInformation.studentRegular.$invalid && vm.validateStudent) || v$students.studentGrantData.studentGrantDataInformation.studentRegular.$pending.$response" class="p-error">Elev type skal udfyles. Er du i tvivl er du højst sandsynlig kostelev</small>
                    </div>
                    <div class="col-12">
                        Hvilket statsborgerskab har du?
                        <div class="flex mt-1">
                            <div class="field-radiobutton mr-3 mb-0">
                                <RadioButton inputId="danish-citizenship" name="citizenship" value="1" v-model="vm.studentGrantData.studentGrantDataInformation.studentHasDanishCitizenship" :class="{'p-invalid':v$students.studentGrantData.studentGrantDataInformation.studentHasDanishCitizenship.$invalid && vm.validateStudent}" required />
                                <label for="danish-citizenship">Dansk</label>
                            </div>
                            <div class="field-radiobutton mr-3 mb-0">
                                <RadioButton inputId="foreign-citizenship" name="citizenship" value="0" v-model="vm.studentGrantData.studentGrantDataInformation.studentHasDanishCitizenship" :class="{'p-invalid':v$students.studentGrantData.studentGrantDataInformation.studentHasDanishCitizenship.$invalid && vm.validateStudent}" required />
                                <label for="foreign-citizenship">Udenlandsk</label>
                            </div>
                        </div>
                        <small v-if="(v$students.studentGrantData.studentGrantDataInformation.studentHasDanishCitizenship.$invalid && vm.validateStudent) || v$students.studentGrantData.studentGrantDataInformation.studentHasDanishCitizenship.$pending.$response" class="p-error">Statsborgerskab skal udfyldes</small>
                    </div>
                </div>
                <div class="box mt-3 grid">
                    <div class="col-12">
                        <label>Hvor mange år har du gået i skole (inklusiv børnehaveklasse)?</label>
                        <!--<InputText id="years-in-school" type="number" class="p-100" pattern="\d*" v-model.number="vm.studentGrantData.studentGrantDataInformation.yearsInSchool" :min="0" :max="20" :class="{'p-invalid':v$students.studentGrantData.studentGrantDataInformation.yearsInSchool.$invalid && vm.validateStudent}" required />-->
                        <InputNumber inputId="years-in-school" class="p-100" v-model.number="vm.studentGrantData.studentGrantDataInformation.yearsInSchool" mode="decimal" :useGrouping="false" :min="0" :max="20" :class="{'p-invalid':v$students.studentGrantData.studentGrantDataInformation.yearsInSchool.$invalid && vm.validateStudent}" required />
                        <small v-if="(v$students.studentGrantData.studentGrantDataInformation.yearsInSchool.$invalid && vm.validateStudent) || v$students.studentGrantData.studentGrantDataInformation.yearsInSchool.$pending.$response" class="p-error">År i skole skal udfyldes og være et helt tal</small>
                    </div>
                    <div class="col-12 md:col-5">
                        <label>Hvilken klasse går du i?</label>
                        <InputText id="class-in-school" type="number" class="p-100" v-model="vm.studentGrantData.studentGrantDataInformation.className" :class="{'p-invalid':v$students.studentGrantData.studentGrantDataInformation.className.$invalid && vm.validateStudent}" required />
                        <small v-if="(v$students.studentGrantData.studentGrantDataInformation.className.$invalid && vm.validateStudent) || v$students.studentGrantData.studentGrantDataInformation.className.$pending.$response" class="p-error">Nuværende klassetrin skal udfyldes</small>
                    </div>
                    <div class="col-12 md:col-7">
                        <label>Skolens navn?</label>
                        <InputText id="schoolname" type="text" class="p-100" v-model="vm.studentGrantData.studentGrantDataInformation.schoolName" :class="{'p-invalid':v$students.studentGrantData.studentGrantDataInformation.schoolName.$invalid && vm.validateStudent}" required />
                        <small v-if="(v$students.studentGrantData.studentGrantDataInformation.schoolName.$invalid && vm.validateStudent) || v$students.studentGrantData.studentGrantDataInformation.schoolName.$pending.$response" class="p-error">Skolens navn skal udfyldes</small>
                    </div>
                    <div class="col-12">
                        Modtager du anden offentlig støtte (fx kontanthjælp)?
                        <div class="flex mt-1">
                            <div class="field-radiobutton mr-3 mb-0">
                                <RadioButton inputId="receiving-cash-benefits-yes" name="receiving-cash-benefits" value="1" v-model="vm.studentGrantData.studentGrantDataInformation.receivingCashBenefits" :class="{'p-invalid':v$students.studentGrantData.studentGrantDataInformation.receivingCashBenefits.$invalid && vm.validateStudent}" required />
                                <label for="receiving-cash-benefits-yes">Ja</label>
                            </div>
                            <div class="field-radiobutton mr-3 mb-0">
                                <RadioButton inputId="receiving-cash-benefits-no" name="receiving-cash-benefits" value="0" v-model="vm.studentGrantData.studentGrantDataInformation.receivingCashBenefits" :class="{'p-invalid':v$students.studentGrantData.studentGrantDataInformation.receivingCashBenefits.$invalid && vm.validateStudent}" required />
                                <label for="receiving-cash-benefits-no">Nej</label>
                            </div>
                        </div>
                        <small v-if="(v$students.studentGrantData.studentGrantDataInformation.receivingCashBenefits.$invalid && vm.validateStudent) || v$students.studentGrantData.studentGrantDataInformation.receivingCashBenefits.$pending.$response" class="p-error">Spørgsmålet skal besvares</small>
                    </div>
                </div>
                <div class="mt-6 pt-6 text-center">
                    <Button icon="pi pi-angle-left" iconPos="left" label="Tilbage" class="m-1" style="min-width:200px" @click="fn.step('start')" />
                    <Button icon="pi pi-angle-right" iconPos="right" label="Næste" class="p-button-success m-1" style="min-width:200px" @click="fn.goToParentsStep(v$students)" />
                </div>
            </form>
        </div>
        <!-- PARENTS -->
        <div v-show="vm.step === 'parents'">
            <form novalidate>
                <div class="mt-6 mb-1 text-center">
                    <h4 class="highlight">Nedenstående udfyldes af <span class="underline">forældre eller forælder/samlever</span>:</h4>
                    <p class="mb-4">Skal kun udfyldes, hvis eleven er under 19 år i den måned, hvor kurset starter.</p>
                </div>
                <div class="box h-full grid relative">
                    <div class="col-12">
                        Er elevens forældre gift eller bor på samme adresse uden at være gift?
                        <div class="mt-1">
                            <div class="field-radiobutton mb-2">
                                <RadioButton inputId="lives-with-both" name="lives-with-both" value="yes" v-model="vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWithBoth" :class="{'p-invalid':v$parents.studentGrantData.studentGrantDataInformation.selectedDataLivesWithBoth.$invalid && vm.validateParents}" required @change="vm.validateParents = false;vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWithOne = '';vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith = ''" />
                                <label for="lives-with-both">Ja</label>
                            </div>
                            <div class="field-radiobutton mb-0">
                                <RadioButton inputId="lives-with-both-mom" name="lives-with-both" value="no" v-model="vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWithBoth" :class="{'p-invalid':v$parents.studentGrantData.studentGrantDataInformation.selectedDataLivesWithBoth.$invalid && vm.validateParents}" required @change="vm.validateParents = false" />
                                <label for="lives-with-both-mom">Nej</label>
                            </div>
                        </div>

                        <small v-if="(v$parents.studentGrantData.studentGrantDataInformation.selectedDataLivesWithBoth.$invalid && vm.validateParents) || v$parents.studentGrantData.studentGrantDataInformation.selectedDataLivesWithBoth.$pending.$response" class="p-error">Spørgsmålet skal besvares</small>
                        <div v-tooltip.top="'Hvis elev bor hos plejefamilie, anses plejeforældre som mor og far'" class="absolute opacity-80 pi pi-question-circle" style="font-size: 1.4rem; color: #333;top:0.5rem;right:0.5rem"></div>
                    </div>
                    <div class="col-12" v-if="vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWithBoth === 'no'">
                        Hvem bor eleven hos?
                        <div class="mt-1">
                            <div class="field-radiobutton mb-2">
                                <RadioButton inputId="lives-with-mom" name="lives-with-one" value="mum" v-model="vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWithOne" :class="{'p-invalid':v$parents.studentGrantData.studentGrantDataInformation.selectedDataLivesWithOne.$invalid && vm.validateParents}" required />
                                <label for="lives-with-mom">Mor</label>
                            </div>
                            <div class="field-radiobutton mb-0">
                                <RadioButton inputId="lives-with-dad" name="lives-with-one" value="dad" v-model="vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWithOne" :class="{'p-invalid':v$parents.studentGrantData.studentGrantDataInformation.selectedDataLivesWithOne.$invalid && vm.validateParents}" required />
                                <label for="lives-with-dad">Far</label>
                            </div>
                        </div>
                        <small v-if="(v$parents.studentGrantData.studentGrantDataInformation.selectedDataLivesWithOne.$invalid && vm.validateParents) || v$parents.studentGrantData.studentGrantDataInformation.selectedDataLivesWithOne.$pending.$response" class="p-error">Spørgsmålet skal besvares</small>
                    </div>

                    <div v-if="vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWithOne === 'mum'">
                        <div class="col-12">
                            Bor mor sammen med en ny ægtefælle/samlever på samme adresse?
                            <div class="mt-1">
                                <div class="field-radiobutton mb-2">
                                    <RadioButton inputId="mum-lives-with-cohabitant" name="lives-with" value="mumAndCohabitant" v-model="vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith" :class="{'p-invalid':v$parents.studentGrantData.studentGrantDataInformation.selectedDataLivesWith.$invalid && vm.validateParents}" required />
                                    <label for="mum-lives-with-cohabitant">Ja</label>
                                </div>
                                <div class="field-radiobutton mb-2">
                                    <RadioButton inputId="mum-lives-with-alone" name="lives-with" value="onlyMum" v-model="vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith" :class="{'p-invalid':v$parents.studentGrantData.studentGrantDataInformation.selectedDataLivesWith.$invalid && vm.validateParents}" required />
                                    <label for="mum-lives-with-alone">Nej</label>
                                </div>
                            </div>
                            <small v-if="(v$parents.studentGrantData.studentGrantDataInformation.selectedDataLivesWith.$invalid && vm.validateParents) || v$parents.studentGrantData.studentGrantDataInformation.selectedDataLivesWith.$pending.$response" class="p-error">Spørgsmålet skal besvares</small>
                        </div>
                    </div>

                    <div v-if="vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWithOne === 'dad'">
                        <div class="col-12">
                            Bor far sammen med en ny ægtefælle/samlever på samme adresse?
                            <div class="mt-1">
                                <div class="field-radiobutton mb-2">
                                    <RadioButton inputId="dad-lives-with-cohabitant" name="lives-with" value="dadAndCohabitant" v-model="vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith" :class="{'p-invalid':v$parents.studentGrantData.studentGrantDataInformation.selectedDataLivesWith.$invalid && vm.validateParents}" required />
                                    <label for="dad-lives-with-cohabitant">Ja</label>
                                </div>
                                <div class="field-radiobutton mb-2">
                                    <RadioButton inputId="dad-lives-with-alone" name="lives-with" value="onlyDad" v-model="vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith" :class="{'p-invalid':v$parents.studentGrantData.studentGrantDataInformation.selectedDataLivesWith.$invalid && vm.validateParents}" required />
                                    <label for="dad-lives-with-alone">Nej</label>
                                </div>
                            </div>
                            <small v-if="(v$parents.studentGrantData.studentGrantDataInformation.selectedDataLivesWith.$invalid && vm.validateParents) || v$parents.studentGrantData.studentGrantDataInformation.selectedDataLivesWith.$pending.$response" class="p-error">Spørgsmålet skal besvares</small>
                        </div>
                    </div>
                </div>
                <!-- MUM -->
                <div class="box mt-3 grid" v-if="vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWithBoth === 'yes' || vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'mumAndCohabitant' || vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'onlyMum'">
                    <div class="col-12">
                        <h3 class="mb-0">Udfyldes af mor</h3>
                    </div>
                    <div class="col-12">
                        <InlineMessage severity="warn w-full" v-if="vm.studentGrantData.studentGrantDataInformation.motherPersonalId && vm.studentGrantData.studentGrantDataInformation.motherPersonalId.indexOf('x') === -1 && !$filters.isCprValid(vm.studentGrantData.studentGrantDataInformation.motherPersonalId)">
                            Vi kan ikke validere dit personnummer. Venligst sikre at det er indtastet korrekt?
                        </InlineMessage>
                    </div>
                    <div class="col-12 md:col-4">
                        <span class="p-float-label">
                            <InputMask id="inputname" type="text" class="p-100" :mask="vm.personalIdMask" slotChar="x" v-model="vm.studentGrantData.studentGrantDataInformation.motherPersonalId" :class="{'p-invalid':v$parents.studentGrantData.studentGrantDataInformation.motherPersonalId.$invalid && vm.validateParents}" autocomplete="off" role="presentation" required />
                            <label for="inputname">Personnummer</label>
                        </span>
                        <small v-if="(v$parents.studentGrantData.studentGrantDataInformation.motherPersonalId.$invalid && vm.validateParents) || v$parents.studentGrantData.studentGrantDataInformation.motherPersonalId.$pending.$response" class="p-error">Personnummer skal udfyldes</small>
                    </div>
                    <div class="col-12 md:col-8">
                        <span class="p-float-label">
                            <InputText id="inputname" type="text" class="p-100" v-model="vm.studentGrantData.studentGrantDataInformation.motherFullName" :class="{'p-invalid':v$parents.studentGrantData.studentGrantDataInformation.motherFullName.$invalid && vm.validateParents}" autocomplete="off" role="presentation" required />
                            <label for="inputname">Navn</label>
                        </span>
                        <small v-if="(v$parents.studentGrantData.studentGrantDataInformation.motherFullName.$invalid && vm.validateParents) || v$parents.studentGrantData.studentGrantDataInformation.motherFullName.$pending.$response" class="p-error">Navn skal udfyldes</small>
                    </div>
                    <div class="col-12">
                        <span class="p-float-label">
                            <InputText id="inputemail" type="text" class="p-100" v-model="vm.studentGrantData.studentGrantDataInformation.motherEmail" :class="{'p-invalid':(v$parents.studentGrantData.studentGrantDataInformation.motherEmail.$invalid || v$parents.studentGrantData.studentGrantDataInformation.uniqueEmails.$invalid) && vm.validateParents}" required />
                            <label for="inputemail">E-mail</label>
                        </span>
                        <small v-if="v$parents.studentGrantData.studentGrantDataInformation.motherEmail.email.$invalid && vm.validateParents" class="p-error">
                            Den indtastede e-mail er ikke gyldig
                        </small>
                        <small v-else-if="(v$parents.studentGrantData.studentGrantDataInformation.motherEmail.$invalid && vm.validateParents) || v$parents.studentGrantData.studentGrantDataInformation.motherEmail.$pending.$response" class="p-error">E-mail skal udfyldes</small>
                        <small v-else-if="(v$parents.studentGrantData.studentGrantDataInformation.uniqueEmails.$invalid && vm.validateParents)" class="p-error">Den angivne e-mail skal være unik og må ikke være identisk med nogen af de øvrige e-mails i dokumentet.</small>
                    </div>
                    <div class="col-12 mt-3">
                        Hvilket statsborgerskab har du?
                        <div class="flex mt-1">
                            <div class="field-radiobutton mr-3 mb-0">
                                <RadioButton inputId="mother-has-danish-citizenship-yes" name="mother-has-danish-citizenship" value="1" v-model="vm.studentGrantData.studentGrantDataInformation.motherHasDanishCitizenship" :class="{'p-invalid':v$parents.studentGrantData.studentGrantDataInformation.motherHasDanishCitizenship.$invalid && vm.validateParents}" required />
                                <label for="mother-has-danish-citizenship-yes">Dansk</label>
                            </div>
                            <div class="field-radiobutton mr-3 mb-0">
                                <RadioButton inputId="mother-has-danish-citizenship-no" name="mother-has-danish-citizenship" value="0" v-model="vm.studentGrantData.studentGrantDataInformation.motherHasDanishCitizenship" :class="{'p-invalid':v$parents.studentGrantData.studentGrantDataInformation.motherHasDanishCitizenship.$invalid && vm.validateParents}" required />
                                <label for="mother-has-danish-citizenship-no">Udenlandsk</label>
                            </div>
                        </div>
                        <small v-if="(v$parents.studentGrantData.studentGrantDataInformation.motherHasDanishCitizenship.$invalid && vm.validateParents) || v$parents.studentGrantData.studentGrantDataInformation.motherHasDanishCitizenship.$pending.$response" class="p-error">Spørgsmålet skal besvares</small>
                    </div>
                    <div class="col-12 mt-3">
                        Har du i <strong class="underline">{{metaData.incomeYear}}</strong> arbejdet enten på Færøerne eller Grønland eller i udlandet?
                        <div class="mt-1">
                            <div class="field-radiobutton mb-2">
                                <RadioButton inputId="mother-has-foreign-income-yes" name="mother-has-foreign-income" value="1" v-model="vm.studentGrantData.studentGrantDataInformation.motherHasForeignIncome" :class="{'p-invalid':v$parents.studentGrantData.studentGrantDataInformation.motherHasForeignIncome.$invalid && vm.validateParents}" required />
                                <label for="mother-has-foreign-income-yes">Ja</label>
                            </div>
                            <div class="field-radiobutton mb-0">
                                <RadioButton inputId="mother-has-foreign-income-no" name="mother-has-foreign-income" value="0" v-model="vm.studentGrantData.studentGrantDataInformation.motherHasForeignIncome" :class="{'p-invalid':v$parents.studentGrantData.studentGrantDataInformation.motherHasForeignIncome.$invalid && vm.validateParents}" required />
                                <label for="mother-has-foreign-income-no">Nej</label>
                            </div>
                        </div>
                        <small v-if="(v$parents.studentGrantData.studentGrantDataInformation.motherHasForeignIncome.$invalid && vm.validateParents) || v$parents.studentGrantData.studentGrantDataInformation.motherHasForeignIncome.$pending.$response" class="p-error">Spørgsmålet skal besvares</small>
                    </div>
                </div>
                <!-- DAD -->
                <div class="box mt-3 grid" v-if="vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWithBoth === 'yes' || vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'dadAndCohabitant' || vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'onlyDad'">
                    <div class="col-12">
                        <h3 class="mb-0">Udfyldes af far</h3>
                    </div>
                    <div class="col-12">
                        <InlineMessage severity="warn w-full" v-if="vm.studentGrantData.studentGrantDataInformation.fatherPersonalId && vm.studentGrantData.studentGrantDataInformation.fatherPersonalId.indexOf('x') === -1 && !$filters.isCprValid(vm.studentGrantData.studentGrantDataInformation.fatherPersonalId)">
                            Vi kan ikke validere dit personnummer. Venligst sikre at det er indtastet korrekt?
                        </InlineMessage>
                    </div>
                    <div class="col-12 md:col-4">
                        <span class="p-float-label">
                            <InputMask id="inputname" type="text" class="p-100" :mask="vm.personalIdMask" slotChar="x" v-model="vm.studentGrantData.studentGrantDataInformation.fatherPersonalId" :class="{'p-invalid':v$parents.studentGrantData.studentGrantDataInformation.fatherPersonalId.$invalid && vm.validateParents}" autocomplete="off" role="presentation" required />
                            <label for="inputname">Personnummer</label>
                        </span>
                        <small v-if="(v$parents.studentGrantData.studentGrantDataInformation.fatherPersonalId.$invalid && vm.validateParents) || v$parents.studentGrantData.studentGrantDataInformation.fatherPersonalId.$pending.$response" class="p-error">Personnummer skal udfyldes</small>
                    </div>
                    <div class="col-12 md:col-8">
                        <span class="p-float-label">
                            <InputText id="inputname" type="text" class="p-100" v-model="vm.studentGrantData.studentGrantDataInformation.fatherFullName" :class="{'p-invalid':v$parents.studentGrantData.studentGrantDataInformation.fatherFullName.$invalid && vm.validateParents}" autocomplete="off" role="presentation" required />
                            <label for="inputname">Navn</label>
                        </span>
                        <small v-if="(v$parents.studentGrantData.studentGrantDataInformation.fatherFullName.$invalid && vm.validateParents) || v$parents.studentGrantData.studentGrantDataInformation.fatherFullName.$pending.$response" class="p-error">Navn skal udfyldes</small>
                    </div>
                    <div class="col-12">
                        <span class="p-float-label">
                            <InputText id="inputemail" type="text" class="p-100" v-model="vm.studentGrantData.studentGrantDataInformation.fatherEmail" :class="{'p-invalid':(v$parents.studentGrantData.studentGrantDataInformation.fatherEmail.$invalid || v$parents.studentGrantData.studentGrantDataInformation.uniqueEmails.$invalid) && vm.validateParents}" required />
                            <label for="inputemail">E-mail</label>
                        </span>
                        <small v-if="v$parents.studentGrantData.studentGrantDataInformation.fatherEmail.email.$invalid && vm.validateParents" class="p-error">
                            Den indtastede e-mail er ikke gyldig
                        </small>
                        <small v-else-if="(v$parents.studentGrantData.studentGrantDataInformation.fatherEmail.$invalid && vm.validateParents) || v$parents.studentGrantData.studentGrantDataInformation.fatherEmail.$pending.$response" class="p-error">E-mail skal udfyldes</small>
                        <small v-else-if="(v$parents.studentGrantData.studentGrantDataInformation.uniqueEmails.$invalid && vm.validateParents)" class="p-error">Den angivne e-mail skal være unik og må ikke være identisk med nogen af de øvrige e-mails i dokumentet.</small>
                    </div>
                    <div class="col-12 mt-3">
                        Hvilket statsborgerskab har du?
                        <div class="flex mt-1">
                            <div class="field-radiobutton mr-3 mb-0">
                                <RadioButton inputId="father-has-danish-citizenship-yes" name="father-has-danish-citizenship" value="1" v-model="vm.studentGrantData.studentGrantDataInformation.fatherHasDanishCitizenship" :class="{'p-invalid':v$parents.studentGrantData.studentGrantDataInformation.fatherHasDanishCitizenship.$invalid && vm.validateParents}" required />
                                <label for="father-has-danish-citizenship-yes">Dansk</label>
                            </div>
                            <div class="field-radiobutton mr-3 mb-0">
                                <RadioButton inputId="father-has-danish-citizenship-no" name="father-has-danish-citizenship" value="0" v-model="vm.studentGrantData.studentGrantDataInformation.fatherHasDanishCitizenship" :class="{'p-invalid':v$parents.studentGrantData.studentGrantDataInformation.fatherHasDanishCitizenship.$invalid && vm.validateParents}" required />
                                <label for="father-has-danish-citizenship-no">Udenlandsk</label>
                            </div>
                        </div>
                        <small v-if="(v$parents.studentGrantData.studentGrantDataInformation.fatherHasDanishCitizenship.$invalid && vm.validateParents) || v$parents.studentGrantData.studentGrantDataInformation.fatherHasDanishCitizenship.$pending.$response" class="p-error">Spørgsmålet skal besvares</small>
                    </div>
                    <div class="col-12 mt-3">
                        Har du i <strong class="underline">{{metaData.incomeYear}}</strong> arbejdet enten på Færøerne eller Grønland eller i udlandet?
                        <div class="mt-1">
                            <div class="field-radiobutton mb-2">
                                <RadioButton inputId="father-has-foreign-income-yes" name="father-has-foreign-income" value="1" v-model="vm.studentGrantData.studentGrantDataInformation.fatherHasForeignIncome" :class="{'p-invalid':v$parents.studentGrantData.studentGrantDataInformation.fatherHasForeignIncome.$invalid && vm.validateParents}" required />
                                <label for="father-has-foreign-income-yes">Ja</label>
                            </div>
                            <div class="field-radiobutton mb-0">
                                <RadioButton inputId="father-has-foreign-income-no" name="father-has-foreign-income" value="0" v-model="vm.studentGrantData.studentGrantDataInformation.fatherHasForeignIncome" :class="{'p-invalid':v$parents.studentGrantData.studentGrantDataInformation.fatherHasForeignIncome.$invalid && vm.validateParents}" required />
                                <label for="father-has-foreign-income-no">Nej</label>
                            </div>
                        </div>
                        <small v-if="(v$parents.studentGrantData.studentGrantDataInformation.fatherHasForeignIncome.$invalid && vm.validateParents) || v$parents.studentGrantData.studentGrantDataInformation.fatherHasForeignIncome.$pending.$response" class="p-error">Spørgsmålet skal besvares</small>
                    </div>
                </div>
                <!-- COHABITANT -->
                <div class="box mt-3 grid" v-if="vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'dadAndCohabitant' || vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'mumAndCohabitant'">
                    <div class="col-12">
                        <h3 class="mb-0">Udfyldes af ny ægtefælle/samlever</h3>
                    </div>
                    <div class="col-12">
                        <InlineMessage severity="warn w-full" v-if="vm.studentGrantData.studentGrantDataInformation.otherPersonalId && vm.studentGrantData.studentGrantDataInformation.otherPersonalId.indexOf('x') === -1 && !$filters.isCprValid(vm.studentGrantData.studentGrantDataInformation.otherPersonalId)">
                            Vi kan ikke validere dit personnummer. Venligst sikre at det er indtastet korrekt?
                        </InlineMessage>
                    </div>
                    <div class="col-12 md:col-4">
                        <span class="p-float-label">
                            <InputMask id="inputname" type="text" class="p-100" :mask="vm.personalIdMask" slotChar="x" v-model="vm.studentGrantData.studentGrantDataInformation.otherPersonalId" :class="{'p-invalid':v$parents.studentGrantData.studentGrantDataInformation.otherPersonalId.$invalid && vm.validateParents}" autocomplete="off" role="presentation" required />
                            <label for="inputname">Personnummer</label>
                        </span>
                        <small v-if="(v$parents.studentGrantData.studentGrantDataInformation.otherPersonalId.$invalid && vm.validateParents) || v$parents.studentGrantData.studentGrantDataInformation.otherPersonalId.$pending.$response" class="p-error">Personnummer skal udfyldes</small>
                    </div>
                    <div class="col-12 md:col-8">
                        <span class="p-float-label">
                            <InputText id="inputname" type="text" class="p-100" v-model="vm.studentGrantData.studentGrantDataInformation.otherFullName" :class="{'p-invalid':v$parents.studentGrantData.studentGrantDataInformation.otherFullName.$invalid && vm.validateParents}" autocomplete="off" role="presentation" required />
                            <label for="inputname">Navn</label>
                        </span>
                        <small v-if="(v$parents.studentGrantData.studentGrantDataInformation.otherFullName.$invalid && vm.validateParents) || v$parents.studentGrantData.studentGrantDataInformation.otherFullName.$pending.$response" class="p-error">Personnummer skal udfyldes</small>
                    </div>
                    <div class="col-12">
                        <span class="p-float-label">
                            <InputText id="inputemail" type="text" class="p-100" v-model="vm.studentGrantData.studentGrantDataInformation.otherEmail" :class="{'p-invalid':(v$parents.studentGrantData.studentGrantDataInformation.otherEmail.$invalid || v$parents.studentGrantData.studentGrantDataInformation.uniqueEmails.$invalid) && vm.validateParents}" required />
                            <label for="inputemail">E-mail</label>
                        </span>
                        <small v-if="v$parents.studentGrantData.studentGrantDataInformation.otherEmail.email.$invalid && vm.validateParents" class="p-error">
                            Den indtastede e-mail er ikke gyldig
                        </small>
                        <small v-else-if="(v$parents.studentGrantData.studentGrantDataInformation.otherEmail.$invalid && vm.validateParents) || v$parents.studentGrantData.studentGrantDataInformation.otherEmail.$pending.$response" class="p-error">E-mail skal udfyldes</small>
                        <small v-else-if="(v$parents.studentGrantData.studentGrantDataInformation.uniqueEmails.$invalid && vm.validateParents)" class="p-error">Den angivne e-mail skal være unik og må ikke være identisk med nogen af de øvrige e-mails i dokumentet.</small>
                    </div>
                    <div class="col-12 mt-3">
                        Hvilket statsborgerskab har du?
                        <div class="flex mt-1">
                            <div class="field-radiobutton mr-3 mb-0">
                                <RadioButton inputId="other-has-danish-citizenship-yes" name="other-has-danish-citizenship" value="1" v-model="vm.studentGrantData.studentGrantDataInformation.otherHasDanishCitizenship" :class="{'p-invalid':v$parents.studentGrantData.studentGrantDataInformation.otherHasDanishCitizenship.$invalid && vm.validateParents}" required />
                                <label for="other-has-danish-citizenship-yes">Dansk</label>
                            </div>
                            <div class="field-radiobutton mr-3 mb-0">
                                <RadioButton inputId="other-has-danish-citizenship-no" name="other-has-danish-citizenship" value="0" v-model="vm.studentGrantData.studentGrantDataInformation.otherHasDanishCitizenship" :class="{'p-invalid':v$parents.studentGrantData.studentGrantDataInformation.otherHasDanishCitizenship.$invalid && vm.validateParents}" required />
                                <label for="other-has-danish-citizenship-no">Udenlandsk</label>
                            </div>
                        </div>
                        <small v-if="(v$parents.studentGrantData.studentGrantDataInformation.otherHasDanishCitizenship.$invalid && vm.validateParents) || v$parents.studentGrantData.studentGrantDataInformation.otherHasDanishCitizenship.$pending.$response" class="p-error">Spørgsmålet skal besvares</small>
                    </div>
                    <div class="col-12 mt-3">
                        Har du i <strong class="underline">{{metaData.incomeYear}}</strong> arbejdet enten på Færøerne eller Grønland eller i udlandet?
                        <div class="mt-1">
                            <div class="field-radiobutton mb-2">
                                <RadioButton inputId="other-has-foreign-income-yes" name="other-has-foreign-income" value="1" v-model="vm.studentGrantData.studentGrantDataInformation.otherHasForeignIncome" :class="{'p-invalid':v$parents.studentGrantData.studentGrantDataInformation.otherHasForeignIncome.$invalid && vm.validateParents}" required />
                                <label for="other-has-foreign-income-yes">Ja</label>
                            </div>
                            <div class="field-radiobutton mb-0">
                                <RadioButton inputId="other-has-foreign-income-no" name="other-has-foreign-income" value="0" v-model="vm.studentGrantData.studentGrantDataInformation.otherHasForeignIncome" :class="{'p-invalid':v$parents.studentGrantData.studentGrantDataInformation.otherHasForeignIncome.$invalid && vm.validateParents}" required />
                                <label for="other-has-foreign-income-no">Nej</label>
                            </div>
                        </div>
                        <small v-if="(v$parents.studentGrantData.studentGrantDataInformation.otherHasForeignIncome.$invalid && vm.validateParents) || v$parents.studentGrantData.studentGrantDataInformation.otherHasForeignIncome.$pending.$response" class="p-error">Spørgsmålet skal besvares</small>
                    </div>
                </div>
                <!-- EXTRA -->
                <div class="box mt-3 grid" v-if="vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWithBoth === 'yes' || vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith">
                    <div class="col-12">
                        <h3 class="mb-0">Udfyldes af en forælder</h3>
                    </div>
                    <div class="col-12">
                        <div class="field-checkbox align-items-start">
                            <Checkbox inputId="divorced" class="mr-2" :binary="true" v-model="vm.studentGrantData.studentGrantDataInformation.selectedDataDivorced" />
                            <label for="divorced" style="line-height:1.2">Er elevens forældre separeret eller skilt? (sæt kryds)</label>
                        </div>
                        <div v-if="vm.studentGrantData.studentGrantDataInformation.selectedDataDivorced">
                            <label for="divorce-date">Ved forældrenes separation eller skilsmisse oplyses dato for separations- eller skilsmissebevilling her:</label>
                            <InputText id="divorce-date" type="text" class="p-100" v-model="vm.studentGrantData.studentGrantDataInformation.divorceDate" :class="{'p-invalid':v$parents.studentGrantData.studentGrantDataInformation.divorceDate.$invalid && vm.validateParents}" autocomplete="off" role="presentation" required />
                            <small v-if="(v$parents.studentGrantData.studentGrantDataInformation.divorceDate.$invalid && vm.validateParents) || v$parents.studentGrantData.studentGrantDataInformation.divorceDate.$pending.$response" class="p-error">Ved forældrenes separation eller skilsmisse skal dato indtastes</small>
                        </div>
                    </div>
                    <div class="col-12">
                        Bor der andre børn <u>under 18 år</u> end eleven på bopælsadressen?
                        <div class="mt-1">
                            <div class="field-radiobutton mb-2">
                                <RadioButton inputId="other-children-yes" name="other-children-yes" value="1" v-model="vm.studentGrantData.studentGrantDataInformation.selectedDataOtherChildren" :class="{'p-invalid':v$parents.studentGrantData.studentGrantDataInformation.selectedDataOtherChildren.$invalid && vm.validateParents}" required />
                                <label for="other-children-yes">Ja</label>
                            </div>
                            <div class="field-radiobutton mb-0">
                                <RadioButton inputId="other-children-no" name="other-children-no" value="0" v-model="vm.studentGrantData.studentGrantDataInformation.selectedDataOtherChildren" :class="{'p-invalid':v$parents.studentGrantData.studentGrantDataInformation.selectedDataOtherChildren.$invalid && vm.validateParents}" required @change="vm.studentGrantData.studentGrantDataInformation.otherChildrenAmount = 0;vm.studentGrantData.studentGrantDataInformation.otherChildrenNames = ''" />
                                <label for="other-children-no">Nej</label>
                            </div>
                        </div>
                        <small v-if="(v$parents.studentGrantData.studentGrantDataInformation.selectedDataOtherChildren.$invalid && vm.validateParents) || v$parents.studentGrantData.studentGrantDataInformation.selectedDataOtherChildren.$pending.$response" class="p-error">Spørgsmålet skal besvares</small>
                        <div v-if="vm.studentGrantData.studentGrantDataInformation.selectedDataOtherChildren === '1'">
                            <h4 class="mt-3">Oplysninger om øvrige børn på elevens bopælsadresse</h4>
                            <label for="other-children-amount">Antal børn under 18 år <span style="background-color: #ef4444; color:white;">(Husk at det er antal uden eleven, og at det er antal børn under 18 år på starttidspunktet)</span>?</label>
                            <InputNumber inputId="other-children-amount" :min="0" :max="20" class="p-100" v-model="vm.studentGrantData.studentGrantDataInformation.otherChildrenAmount" :class="{'p-invalid':v$parents.studentGrantData.studentGrantDataInformation.otherChildrenAmount.$invalid && vm.validateParents}" required />
                            <div class="mb-2"><small v-if="(v$parents.studentGrantData.studentGrantDataInformation.otherChildrenAmount.$invalid && vm.validateParents) || v$parents.studentGrantData.studentGrantDataInformation.otherChildrenAmount.$pending.$response" class="p-error">Antal skal udfyldes. Husk at det er eksklusiv eleven</small></div>
                            <label>Barnets/børnenes navn(e) samt fødselsdato:</label>
                            <Textarea id="inputdescription" type="text" class="p-100" v-model="vm.studentGrantData.studentGrantDataInformation.otherChildrenNames" :autoResize="true" rows="5" cols="30" :class="{'p-invalid':v$parents.studentGrantData.studentGrantDataInformation.otherChildrenNames.$invalid && vm.validateParents}" required placeholder="Eksempel: John Doe 28.01.20" />
                            <small v-if="(v$parents.studentGrantData.studentGrantDataInformation.otherChildrenNames.$invalid && vm.validateParents) || v$parents.studentGrantData.studentGrantDataInformation.otherChildrenNames.$pending.$response" class="p-error">Navn(e) skal udfyldes</small>
                        </div>
                    </div>
                    <!--<div class="col-12">
            <div class="field-checkbox align-items-start">
                <Checkbox inputId="other-children" class="mr-2" :binary="true" v-model="vm.studentGrantData.studentGrantDataInformation.selectedDataOtherChildren" />
                <label for="other-children" style="line-height:1.2">Bor der andre børn end eleven på bopælsadressen? (sæt kryds)</label>
            </div>
            <div v-if="vm.studentGrantData.studentGrantDataInformation.selectedDataOtherChildren">
                <h4 class="mt-3">Oplysninger om øvrige børn på elevens bopælsadresse</h4>
                <label for="other-children-amount">Antal børn under 18 år <span style="background-color: #ffd500">(eksklusiv eleven)</span>?</label>
                <InputText id="other-children-amount" type="number" class="p-100" v-model="vm.studentGrantData.studentGrantDataInformation.otherChildrenAmount" :class="{'p-invalid':v$parents.studentGrantData.studentGrantDataInformation.otherChildrenAmount.$invalid && vm.validateParents}" required />
                <div class="mb-2"><small v-if="(v$parents.studentGrantData.studentGrantDataInformation.otherChildrenAmount.$invalid && vm.validateParents) || v$parents.studentGrantData.studentGrantDataInformation.otherChildrenAmount.$pending.$response" class="p-error">Antal skal udfyldes. Husk at det er eksklusiv eleven</small></div>
                <label>Barnets/børnenes navn(e):</label>
                <Textarea id="inputdescription" type="text" class="p-100" v-model="vm.studentGrantData.studentGrantDataInformation.otherChildrenNames" :autoResize="true" rows="5" cols="30" :class="{'p-invalid':v$parents.studentGrantData.studentGrantDataInformation.otherChildrenNames.$invalid && vm.validateParents}" required />
                <small v-if="(v$parents.studentGrantData.studentGrantDataInformation.otherChildrenNames.$invalid && vm.validateParents) || v$parents.studentGrantData.studentGrantDataInformation.otherChildrenNames.$pending.$response" class="p-error">Navn(e) skal udfyldes</small>
            </div>
        </div>-->
                    <div class="col-12">
                        <h4 class="my-3">Særlige oplysninger</h4>
                        <div class="field-checkbox align-items-start">
                            <Checkbox inputId="include-foreign-documentation" class="mr-2" :binary="true" v-model="vm.studentGrantData.studentGrantDataInformation.requiresRefugeeDocumentation" />
                            <label for="include-foreign-documentation" style="line-height:1.2">For flygtninge med flygtningestatus vedlægges dokumentation. (sæt kryds)</label>
                        </div>
                        <div class="field-checkbox align-items-start">
                            <Checkbox inputId="include-abroad-work-income-documentation" class="mr-2" :binary="true" v-model="vm.studentGrantData.studentGrantDataInformation.requiresForeignIncomeDocumentation" />
                            <label for="include-abroad-work-income-documentation" style="line-height:1.2">Hvis der i indkomståret (<strong>{{metaData.incomeYear}}</strong>) har været indkomst enten på Færøerne eller Grønland eller i udlandet, vedlægges redegørelse med dokumentation fra offentlig myndighed for indkomstforholdene hele det pågældende indkomstår. (sæt kryds)</label>
                        </div>
                        <div class="field-checkbox align-items-start">
                            <Checkbox inputId="include-foreign-income-documentation" class="mr-2" :binary="true" v-model="vm.studentGrantData.studentGrantDataInformation.requiresForeignCitizenTaxDocumentation" />
                            <label for="include-foreign-income-documentation" style="line-height:1.2">
                                For udenlandske statsborgere, for hvem SKAT ikke kan opgøre et indkomstgrundlag, vedlægges dokumentation for tilknytningen til Danmark samt dokumentation fra offentlig myndighed for indkomstforholdene hele det pågældende indkomstår (<strong>{{metaData.incomeYear}}</strong>). (sæt kryds)
                            </label>
                        </div>
                        <div v-if="vm.studentGrantData.studentGrantDataInformation.requiresRefugeeDocumentation || vm.studentGrantData.studentGrantDataInformation.requiresForeignIncomeDocumentation || vm.studentGrantData.studentGrantDataInformation.requiresForeignCitizenTaxDocumentation">
                            <h4 class="my-3">Upload dokumentation</h4>
                            <FileUpload name="uploadDocumentation[]" :maxFileSize="1000000" :fileLimit="5" :customUpload="true" @uploader="fn.onDocumentationUpload($event)" :multiple="true" chooseLabel="Vælg" :showCancelButton="false">
                                <template #empty>
                                    <p v-if="!vm.showUploadedMessage">Træk og slip filer hertil for at uploade eller tryk på knappen "Vælg"</p>
                                    <InlineMessage severity="success" class="w-full" v-else>
                                        Filen/filerne er hermed uploadet
                                    </InlineMessage>
                                </template>
                            </FileUpload>
                        </div>
                    </div>
                </div>
                <div class="mt-6 pt-6 text-center">
                    <Button icon="pi pi-angle-left" iconPos="left" label="Tilbage" class="m-1" style="min-width:200px" @click="fn.step('student')" />
                    <Button icon="pi pi-angle-right" iconPos="right" label="Gå til godkendelse" class="p-button-success m-1" style="min-width:200px" @click="fn.validateAndSubmit(v$parents)" />
                </div>
            </form>
        </div>

        <ConfirmDialog group="confirmDocumentationNotUploaded" :style="{'max-width':'650px',width: '100%', margin:'0.5rem'}">
            Du har valgt en eller flere filer som dokumentation, der ikke er uploadet. Hvis du fortsætter, vil det går tabt. <br /><br />Hvis du ønsker at uploade, klik "Nej" og efterfølgende på "Upload" knappen.<br /><br /><br /><strong>Er du sikker på, at du vil fortsætte?</strong>
        </ConfirmDialog>
    </div>
</template>


<script>
    import { defineComponent, reactive, ref, computed } from 'vue';
    import { useConfirm } from "primevue/useconfirm";
    import * as dawa from '@/3rdparty/dawa-autocomplete2';
    import { requiredIf, required, email, integer } from "@vuelidate/validators";
    import { useVuelidate } from "@vuelidate/core";
    import { useToast } from 'primevue/usetoast';


    export default defineComponent({
        name: 'StudentGrantForm',
        props: {
            metaData: Object,
            data: Object
        },
        emits: ['submit', 'uploadAsset'],

        setup(props, { emit }) {

            let top = ref(null);
            const confirm = useConfirm();
            const toast = useToast();
            const vm = reactive({
                step: 'start',
                studentGrantData: { studentGrantDataInformation: {} },
                dawaInitialized: false,
                showUploadedMessage: false,
                validateStudent: false,
                validateParents: false,
                personalIdMask: null
            });
            const cantBeZeroWhenNamesAreEntered = (value) => {
                if (vm.studentGrantData.studentGrantDataInformation.otherChildrenNames && value == 0) {
                    return false;
                }
                return true;
            };
            const uniqueEmails = (value, parentVm) => {
                const emails = [
                    parentVm.studentGrantDataInformation.email,
                    parentVm.studentGrantDataInformation.motherEmail,
                    parentVm.studentGrantDataInformation.fatherEmail,
                    parentVm.studentGrantDataInformation.otherEmail
                ];

                const validEmails = emails.filter(email => email && email.trim() !== '');
                const isUnique = validEmails.length === validEmails.filter((email, index, array) => array.indexOf(email) === index).length;

                return isUnique;
            };
            const validationRulesStudent = computed(() => {
                return {
                    studentGrantData: {
                        studentGrantDataInformation: {
                            studentPersonalIdEncrypted: { required },
                            studentName: { required },
                            dawa: { required: requiredIf(!vm.enterAddressManually) },
                            address: { required },
                            zip: { required: requiredIf(vm.enterAddressManually) },
                            city: { required: requiredIf(vm.enterAddressManually) },
                            communeName: { required: requiredIf(vm.enterAddressManually) },
                            email: { email },
                            studentRegular: { required },
                            studentHasDanishCitizenship: { required },
                            yearsInSchool: { required, integer },
                            className: { required },
                            schoolName: { required },
                            receivingCashBenefits: { required }
                        }
                    }
                }
            });
            const validationRulesParents = computed(() => {
                return {
                    studentGrantData: {
                        studentGrantDataInformation: {
                            selectedDataLivesWithBoth: { required },
                            selectedDataLivesWithOne: {
                                required: requiredIf(vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWithBoth === 'no')
                            },
                            selectedDataLivesWith: {
                                required: requiredIf(
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWithOne === 'mum' ||
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWithOne === 'dad')
                            },
                            // MOTHER
                            motherPersonalId: {
                                required: requiredIf(
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWithBoth === 'yes' ||
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'mumAndCohabitant' ||
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'onlyMum')
                            },
                            motherFullName: {
                                required: requiredIf(
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWithBoth === 'yes' ||
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'mumAndCohabitant' ||
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'onlyMum')
                            },
                            motherEmail: {
                                required: requiredIf(
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWithBoth === 'yes' ||
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'mumAndCohabitant' ||
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'onlyMum'),
                                email
                            },
                            motherHasDanishCitizenship: {
                                required: requiredIf(
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWithBoth === 'yes' ||
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'mumAndCohabitant' ||
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'onlyMum')
                            },
                            motherHasForeignIncome: {
                                required: requiredIf(
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWithBoth === 'yes' ||
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'mumAndCohabitant' ||
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'onlyMum')
                            },
                            // FATHER
                            fatherPersonalId: {
                                required: requiredIf(
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWithBoth === 'yes' ||
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'dadAndCohabitant' ||
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'onlyDad')
                            },
                            fatherFullName: {
                                required: requiredIf(
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWithBoth === 'yes' ||
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'dadAndCohabitant' ||
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'onlyDad')
                            },
                            fatherEmail: {
                                required: requiredIf(
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWithBoth === 'yes' ||
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'dadAndCohabitant' ||
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'onlyDad'),
                                email
                            },
                            fatherHasDanishCitizenship: {
                                required: requiredIf(
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWithBoth === 'yes' ||
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'dadAndCohabitant' ||
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'onlyDad')
                            },
                            fatherHasForeignIncome: {
                                required: requiredIf(
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWithBoth === 'yes' ||
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'dadAndCohabitant' ||
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'onlyDad')
                            },
                            // COHABITANT
                            otherPersonalId: {
                                required: requiredIf(
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'dadAndCohabitant' ||
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'mumAndCohabitant')
                            },
                            otherFullName: {
                                required: requiredIf(
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'dadAndCohabitant' ||
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'mumAndCohabitant')
                            },
                            otherEmail: {
                                required: requiredIf(
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'dadAndCohabitant' ||
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'mumAndCohabitant'),
                                email
                            },
                            otherHasDanishCitizenship: {
                                required: requiredIf(
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'dadAndCohabitant' ||
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'mumAndCohabitant')
                            },
                            otherHasForeignIncome: {
                                required: requiredIf(
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'dadAndCohabitant' ||
                                    vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'mumAndCohabitant')
                            },
                            // EXTRA
                            divorceDate: {
                                required: requiredIf(vm.studentGrantData.studentGrantDataInformation.selectedDataDivorced)
                            },
                            selectedDataOtherChildren: {
                                required
                            },
                            otherChildrenAmount: {
                                required: requiredIf(vm.studentGrantData.studentGrantDataInformation.selectedDataOtherChildren === '1'),
                                cantBeZeroWhenNamesAreEntered
                            },
                            otherChildrenNames: {
                                required: requiredIf(vm.studentGrantData.studentGrantDataInformation.selectedDataOtherChildren === '1')
                            },
                            uniqueEmails: {
                                $validator: (value, parentVm) => uniqueEmails(value, parentVm)
                            }
                        }
                    }
                }
            });

            let v$students = useVuelidate(validationRulesStudent, vm);
            let v$parents = useVuelidate(validationRulesParents, vm);


            const fn = {
                goToStudentDataStep: function () {
                    if (props.metaData.inputCompleted) {
                        vm.personalIdMask = '9999**-****';
                        vm.studentGrantData = revertBoolValueToBoolString(props.metaData.studentGrantData);
                    } else {
                        vm.studentGrantData.studentGrantDataInformation.studentRegular = '1';
                        vm.personalIdMask = '999999-9999';
                    }

                    fn.step('student');
                    initDawa();
                },
                goToParentsStep: function (form) {
                    vm.validateStudent = true;
                    if (form.$invalid) {
                        //Scroll to first error
                        setTimeout(() => {
                            var errors = document.getElementsByClassName('p-invalid');
                            if (errors && errors.length) {
                                errors[0].scrollIntoView();
                            }
                            toast.add({ severity: 'error', summary: 'Hov, du kan ikke fortsætte endnu', detail: 'Der mangler stadig at blive udfyldt nogle felter', life: 6000 });
                        }, 100);

                        return;
                    }
                    fn.step('parents');
                    vm.validateStudent = false;
                },
                step: function (stepname) {
                    vm.step = stepname;
                    if (top && top.value) {
                        top.value.scrollIntoView();
                    }
                },
                validateAndSubmit: function (form) {
                    vm.validateParents = true;
                    if (form.$invalid) {
                        //Scroll to first error
                        setTimeout(() => {
                            var errors = document.getElementsByClassName('p-invalid');
                            if (errors && errors.length) {
                                errors[0].scrollIntoView();
                            }
                            toast.add({ severity: 'error', summary: 'Næsten i mål!', detail: 'Der mangler stadig nogle oplysninger, før du kan fortsætte.', life: 6000 });
                        }, 100);

                        return;
                    }
                    fn.submit();
                },
                submit: function () {

                    // sanitize data, edgecase
                    // todo: clever way to do it
                    if (vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWithBoth === 'yes' || vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'onlyMum' || vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'onlyDad') {
                        vm.studentGrantData.studentGrantDataInformation.otherPersonalId = '';
                        vm.studentGrantData.studentGrantDataInformation.otherFullName = '';
                        vm.studentGrantData.studentGrantDataInformation.otherEmail = '';
                    }
                    if (vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'onlyMum' || vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'mumAndCohabitant') {
                        vm.studentGrantData.studentGrantDataInformation.fatherPersonalId = '';
                        vm.studentGrantData.studentGrantDataInformation.fatherFullName = '';
                        vm.studentGrantData.studentGrantDataInformation.fatherEmail = '';
                    }
                    if (vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'onlyDad' || vm.studentGrantData.studentGrantDataInformation.selectedDataLivesWith === 'dadAndCohabitant') {
                        vm.studentGrantData.studentGrantDataInformation.motherPersonalId = '';
                        vm.studentGrantData.studentGrantDataInformation.motherFullName = '';
                        vm.studentGrantData.studentGrantDataInformation.motherEmail = '';
                    }



                    if (document.getElementsByClassName('p-fileupload-file').length) {
                        confirm.require({
                            group: 'confirmDocumentationNotUploaded',
                            header: 'Dokumentation ikke uploadet!',
                            icon: 'pi pi-exclamation-triangle',
                            acceptLabel: 'Ja',
                            rejectLabel: 'Nej',
                            accept: () => {
                                emit('submit', convertBoolStringToBoolValue(vm.studentGrantData));
                            }
                        });
                    } else {
                        emit('submit', convertBoolStringToBoolValue(vm.studentGrantData));
                    }
                },
                onDocumentationUpload: (event) => {
                    if (event.files) {
                        for (var i = 0, l = event.files.length; i < l; i++) {
                            emit('uploadAsset', event.files[i]);
                            vm.showUploadedMessage = true;
                        }

                    }
                }
            }

            function initDawa() {
                if (!vm.dawaInitialized) {
                    setTimeout(() => {
                        dawa.dawaAutocomplete(document.getElementById('dawa-autocomplete-input'), {
                            select: function (selected) {
                                if (selected && selected.data) {
                                    var address = selected.tekst;
                                    var addressSplitted = address.split(',');
                                    if (addressSplitted.length > 1) {
                                        address = addressSplitted.slice(0, -1).join(',')
                                    }

                                    vm.studentGrantData.studentGrantDataInformation.dawa = selected.tekst;
                                    vm.studentGrantData.studentGrantDataInformation.address = address;
                                    vm.studentGrantData.studentGrantDataInformation.zip = selected.data.postnr;
                                    vm.studentGrantData.studentGrantDataInformation.city = selected.data.postnrnavn;
                                    vm.studentGrantData.studentGrantDataInformation.communeCode = selected.data.kommunekode;
                                }
                            }
                        });
                        vm.dawaInitialized = true;
                    }, 100);
                }
            }
            function revertBoolValueToBoolString(data) {
                if (data && data.studentGrantDataInformation) {
                    data.studentGrantDataInformation.studentRegular = data.studentGrantDataInformation.studentRegular ? '1' : '0';
                    data.studentGrantDataInformation.studentHasDanishCitizenship = data.studentGrantDataInformation.studentHasDanishCitizenship ? '1' : '0';
                    data.studentGrantDataInformation.receivingCashBenefits = data.studentGrantDataInformation.receivingCashBenefits ? '1' : '0';
                    data.studentGrantDataInformation.motherHasDanishCitizenship = data.studentGrantDataInformation.motherHasDanishCitizenship ? '1' : '0';
                    data.studentGrantDataInformation.motherHasForeignIncome = data.studentGrantDataInformation.motherHasForeignIncome ? '1' : '0';
                    data.studentGrantDataInformation.fatherHasDanishCitizenship = data.studentGrantDataInformation.fatherHasDanishCitizenship ? '1' : '0';
                    data.studentGrantDataInformation.fatherHasForeignIncome = data.studentGrantDataInformation.fatherHasForeignIncome ? '1' : '0';
                    data.studentGrantDataInformation.otherHasDanishCitizenship = data.studentGrantDataInformation.otherHasDanishCitizenship ? '1' : '0';
                    data.studentGrantDataInformation.otherHasForeignIncome = data.studentGrantDataInformation.otherHasForeignIncome ? '1' : '0';
                    data.studentGrantDataInformation.selectedDataOtherChildren = data.studentGrantDataInformation.selectedDataOtherChildren ? '1' : '0';

                    return data;
                } else {
                    return {};
                }
            }
            function convertBoolStringToBoolValue(data) {
                if (data && data.studentGrantDataInformation) {
                    if (data.studentGrantDataInformation.studentRegular) {
                        data.studentGrantDataInformation.studentRegular = data.studentGrantDataInformation.studentRegular === '1'
                    }
                    if (data.studentGrantDataInformation.studentHasDanishCitizenship) {
                        data.studentGrantDataInformation.studentHasDanishCitizenship = data.studentGrantDataInformation.studentHasDanishCitizenship === '1'
                    }
                    if (data.studentGrantDataInformation.receivingCashBenefits) {
                        data.studentGrantDataInformation.receivingCashBenefits = data.studentGrantDataInformation.receivingCashBenefits === '1'
                    }
                    if (data.studentGrantDataInformation.motherHasDanishCitizenship) {
                        data.studentGrantDataInformation.motherHasDanishCitizenship = data.studentGrantDataInformation.motherHasDanishCitizenship === '1'
                    }
                    if (data.studentGrantDataInformation.motherHasForeignIncome) {
                        data.studentGrantDataInformation.motherHasForeignIncome = data.studentGrantDataInformation.motherHasForeignIncome === '1'
                    }
                    if (data.studentGrantDataInformation.fatherHasDanishCitizenship) {
                        data.studentGrantDataInformation.fatherHasDanishCitizenship = data.studentGrantDataInformation.fatherHasDanishCitizenship === '1'
                    }
                    if (data.studentGrantDataInformation.fatherHasForeignIncome) {
                        data.studentGrantDataInformation.fatherHasForeignIncome = data.studentGrantDataInformation.fatherHasForeignIncome === '1'
                    }
                    if (data.studentGrantDataInformation.otherHasDanishCitizenship) {
                        data.studentGrantDataInformation.otherHasDanishCitizenship = data.studentGrantDataInformation.otherHasDanishCitizenship === '1'
                    }
                    if (data.studentGrantDataInformation.otherHasForeignIncome) {
                        data.studentGrantDataInformation.otherHasForeignIncome = data.studentGrantDataInformation.otherHasForeignIncome === '1'
                    }
                    if (data.studentGrantDataInformation.selectedDataOtherChildren) {
                        data.studentGrantDataInformation.selectedDataOtherChildren = data.studentGrantDataInformation.selectedDataOtherChildren === '1'
                    }
                }

                return data;
            }

            return { v$students, v$parents, top, vm, fn };
        }
    });
</script>
<style scoped lang="scss">

    .steps:after {
        position: absolute;
        top: 20px;
        left: 100px;
        right: 100px;
        content: '';
        height: 1px;
        background: #ced4da;
        z-index: -1;
    }

    .step {
        position: relative;
        width: 200px;


        &:before {
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            background: #f3f4ff;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            z-index: 0;
        }
    }

    .step-number {
        position: relative;
        background: #4849a1;
        width: 40px;
        height: 40px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        border-radius: 50%;
        color: white;
        font-size: 24px;
        opacity: 0.5;
    }

    .step-description {
        opacity: 0.5;
    }

    .active {
        .step-number {
            background-color: #39bc6c;
            opacity: 1;
        }

        .step-description {
            opacity: 1;
        }
    }
</style>
