<template>
    <div class="page-document">
        <div v-if="vm.busy" style="margin: 100px auto 0" class="loader-wrapper">
            <ProgressSpinner />
        </div>
        <div v-else style="width:100%">
            <div v-if="vm.view === 'access'" class="document" style="background: none; border: none; max-width: 700px;">
                <form v-if="vm.lang==='en'" class="text-center" style="width:100%">
                    <h1>Document from<br />{{vm.accessInformation.customerName}}</h1>
                    <div class="my-5">
                        <div v-if="vm.accessInformation.requiresAccessCode">
                            <p>
                                Confirm that you are the correct recipient by entering the PIN code for the document.
                            </p>
                            <div v-if="vm.showPincodeEntry">
                                <p>Haven't received a PIN code? <Button type="button" text class="py-0 px-2 underline" label="Send PIN code" @click="fn.requestCode"></Button></p>
                                <pincode v-model="vm.accessInformation.code" :length="vm.accessInformation.accessCodeLength"></pincode>
                                <p v-if="vm.authenticationFailure" style="color:red">The entered PIN code is incorrect or expired</p>
                                <div class="mt-6">
                                    <Button label="View document" type="submit" class="p-button-success p-button-lg mx-2" :disabled="!vm.accessInformation.code || vm.accessInformation.code.length !== vm.accessInformation.accessCodeLength" @click="fn.showDocument" />
                                </div>
                            </div>
                            <div v-else class="mt-6">
                                <Button type="button" class="p-button p-button-lg mx-2" label="Send PIN code" @click="fn.requestCode"></Button>
                            </div>
                        </div>
                        <div v-else>
                            <p>If you have received an email with a link to this document by mistake, we kindly ask you to inform the sender about the error using the reply function. At the same time please close the link again.</p>
                            <Button label="View document" type="submit" class="p-button-success p-button-lg mx-2 mt-6" @click="fn.showDocument" />
                        </div>
                    </div>

                </form>
                <form v-else-if="vm.lang==='da_en'" class="text-center" style="width:100%">
                    <h2>Dokument fra {{vm.accessInformation.customerName}} <br /><em class="secondary-language">Document from {{vm.accessInformation.customerName}}</em></h2>
                    <div class="my-5">
                        <div v-if="vm.accessInformation.requiresAccessCode">
                            <p>
                                Bekræft at du er rette modtager ved at indtaste pinkoden til dokumentet.<br />
                                <em class="secondary-language">Confirm that you are the correct recipient by entering the PIN code for the document.</em>
                            </p>
                            <div v-if="vm.showPincodeEntry">
                                <p>Ikke modtaget en pinkode?<br /><em class="secondary-language">Haven't received a PIN code?</em><br /><Button type="button" text class="py-0 px-2 underline" label="Send pinkode / Send PIN code" @click="fn.requestCode"></Button></p>
                                <pincode v-model="vm.accessInformation.code" :length="vm.accessInformation.accessCodeLength"></pincode>
                                <p v-if="vm.authenticationFailure" style="color:red">The entered PIN code is incorrect or expired</p>
                                <div class="mt-6">
                                    <Button label="Se dokument / View document" type="submit" class="p-button-success p-button-lg mx-2" :disabled="!vm.accessInformation.code || vm.accessInformation.code.length !== vm.accessInformation.accessCodeLength" @click="fn.showDocument" />
                                </div>
                            </div>
                            <div v-else class="mt-6">
                                <Button type="button" class="p-button p-button-lg mx-2" label="Send pinkode / Send PIN code" @click="fn.requestCode"></Button>
                            </div>
                        </div>
                        <div v-else>
                            <p>
                                Hvis du ved en fejl har modtaget en e-mail med et link hertil, beder vi dig venligst om at informere afsenderen om fejlen ved hjælp af svar-funktionen og samtidig lukke linket igen.<br />
                                <em class="secondary-language">If you have received an email with a link to this document by mistake, we kindly ask you to inform the sender about the error using the reply function. At the same time please close the link again.</em>
                            </p>
                            <Button label="Se dokument / View document" type="submit" class="p-button-success p-button-lg mx-2 mt-6" @click="fn.showDocument" />
                        </div>
                    </div>

                </form>
                <form v-else class="text-center" style="width:100%">
                    <h1>Dokument fra<br />{{vm.accessInformation.customerName}}</h1>
                    <div class="my-5">
                        <div v-if="vm.accessInformation.requiresAccessCode">
                            <p>
                                Bekræft at du er rette modtager ved at indtaste pinkoden til dokumentet.
                            </p>
                            <div v-if="vm.showPincodeEntry">
                                <p>Ikke modtaget en pinkode? <Button type="button" text class="py-0 px-2 underline" label="Send pinkode" @click="fn.requestCode"></Button></p>
                                <pincode v-model="vm.accessInformation.code" :length="vm.accessInformation.accessCodeLength"></pincode>
                                <p v-if="vm.authenticationFailure" style="color:red">Den indtastede pinkode er forkert eller udløbet</p>
                                <div class="mt-6">
                                    <Button label="Se dokument" type="submit" class="p-button-success p-button-lg mx-2" :disabled="!vm.accessInformation.code || vm.accessInformation.code.length !== vm.accessInformation.accessCodeLength" @click="fn.showDocument" />
                                </div>
                            </div>
                            <div v-else class="mt-6">
                                <Button type="button" class="p-button p-button-lg mx-2" label="Send pinkode" @click="fn.requestCode"></Button>
                            </div>
                        </div>
                        <div v-else>
                            <p>Hvis du ved en fejl har modtaget en e-mail med et link hertil, beder vi dig venligst om at informere afsenderen om fejlen ved hjælp af svar-funktionen og samtidig lukke linket igen.</p>
                            <Button label="Se dokument" type="submit" class="p-button-success p-button-lg mx-2 mt-6" @click="fn.showDocument" />
                        </div>
                    </div>

                </form>
            </div>
            <div v-if="vm.view === 'document'">
                <div v-if="vm.error" class="document">
                    <div v-if="vm.lang==='en'" class="text-center" style="width:100%">
                        <h2>Øv. Der skete en fejl!</h2>
                        <pre>{{vm.error}}</pre>
                        <p>Prøv igen senere eller kontakt afsender.</p>
                    </div>
                    <div v-else-if="vm.lang==='da_en'" class="text-center" style="width:100%">
                        <h2>Øv. Der skete en fejl! / Oh no. Something wrong happened!</h2>
                        <pre>{{vm.error}}</pre>
                        <p>Prøv igen senere eller kontakt afsender. / Try again later or contact the sender.</p>
                    </div>
                    <div v-else class="text-center" style="width:100%">
                        <h2>Oh no. Something wrong happened!</h2>
                        <pre>{{vm.error}}</pre>
                        <p>Try again later or contact the sender.</p>
                    </div>
                </div>
                <div v-else>
                    <component :is="vm.dynamicHtml"></component>
                    <div v-if="vm.saved" class="overlay overlay-saved">
                        <div v-if="vm.lang==='en'" class="text-center pb-6" style="max-width: 490px; cursor: pointer">
                            <SvgSuccess />
                            <h2>Your data has been safely submitted and you can now close the page.</h2>
                            <p @click="vm.saved = false">
                                If you want to see or change the entered data, you can <span style="text-decoration:underline">click here</span>
                            </p>
                        </div>
                        <div v-else-if="vm.lang==='da_en'" class="text-center pb-6" style="max-width: 490px; cursor: pointer">
                            <SvgSuccess />
                            <h2>Dine data er sikkert indsendt, og du kan nu lukke siden. / Your data has been safely submitted and you can now close the page.</h2>
                            <p @click="vm.saved = false">
                                Hvis du vil se eller rette de indtastede data kan du <span style="text-decoration:underline">klikke her</span> /
                                If you want to see or change the entered data, you can <span style="text-decoration:underline">click here</span>
                            </p>
                        </div>
                        <div v-else class="text-center pb-6" style="max-width: 490px; cursor: pointer">
                            <SvgSuccess />
                            <h2>Dine data er sikkert indsendt, og du kan nu lukke siden.</h2>
                            <p @click="vm.saved = false">
                                Hvis du vil se eller rette de indtastede data kan du <span style="text-decoration:underline">klikke her</span>
                            </p>
                        </div>
                    </div>
                    <div v-if="vm.overlayApprove" class="overlay">
                        <div v-if="vm.lang==='en'" class="text-center pb-5" style="max-width: 490px;">
                            <h1 class="pb-2">{{vm.signer.name}}</h1>
                            <h4 class="pb-4">Are you sure you want to approve the document?</h4>
                            <div class="mt-5">
                                <Button label="No" class="p-button p-button-lg p-button-link mx-2" @click="vm.overlayApprove=false" />
                                <Button label="Yes" class="p-button-success p-button-lg mx-2" @click="fn.confirmApprove" />
                            </div>
                        </div>
                        <div v-else-if="vm.lang==='da_en'" class="text-center pb-5" style="max-width: 490px;">
                            <h1 class="pb-2">{{vm.signer.name}}</h1>
                            <h4 class="pb-4">Er du sikker på, at du vil godkende dokumentet? / Are you sure you want to approve the document?</h4>
                            <div class="mt-5">
                                <Button label="Nej / No" class="p-button p-button-lg p-button-link mx-2" @click="vm.overlayApprove=false" />
                                <Button label="Ja / Yes " class="p-button-success p-button-lg mx-2" @click="fn.confirmApprove" />
                            </div>
                        </div>
                        <div v-else class="text-center pb-5" style="max-width: 490px;">
                            <h1 class="pb-2">{{vm.signer.name}}</h1>
                            <h4 class="pb-4">Er du sikker på, at du vil godkende dokumentet?</h4>
                            <div class="mt-5">
                                <Button label="Nej" class="p-button p-button-lg p-button-link mx-2" @click="vm.overlayApprove=false" />
                                <Button label="Ja" class="p-button-success p-button-lg mx-2" @click="fn.confirmApprove" />
                            </div>
                        </div>
                    </div>
                    <div v-if="vm.overlaySignPad" class="overlay">
                        <div v-if="vm.lang==='en'" class="text-center pb-5 w-full" style="max-width: 520px;user-select:none">
                            <h2 class="pb-4">Draw your signature below</h2>
                            <e-signature :lang="vm.lang" :signerName="vm.signer.name" ref="eSignatureRef"></e-signature>
                            <div class="mt-4 mx-3" :style="{'visibility': eSignatureTouched ? 'visible' : 'hidden'}">
                                By clicking "submit signature", I confirm that I am<br /><strong class="uppercase">{{vm.signer.name}}</strong><br />the signatory by drawing my signature.
                                <div class="mt-5">
                                    <Button label="Close" class="p-button p-button-lg p-button-link mx-2" @click="vm.overlaySignPad=false" />
                                    <Button label="Submit signature" class="p-button-success p-button-lg mx-2" @click="fn.confirmPadSign" :disabled="!eSignatureTouched" />
                                </div>
                            </div>
                        </div>
                        <div v-else-if="vm.lang==='da_en'" class="text-center pb-5 w-full" style="max-width: 520px;user-select:none">
                            <h2 class="pb-4">Tegn din underskrift herunder<br /><em class="secondary-language">Draw your signature below</em></h2>
                            <e-signature :lang="vm.lang" :signerName="vm.signer.name" ref="eSignatureRef"></e-signature>
                            <div class="mt-4 mx-3" :style="{'visibility': eSignatureTouched ? 'visible' : 'hidden'}">
                                Ved at klikke "indsend underskrift", bekræfter jeg, at jeg er<br /><strong class="uppercase">{{vm.signer.name}}</strong><br />som har underskrevet ved at tegne min underskrift. <br /><br />
                                <em class="secondary-language">By clicking "submit signature", I confirm that I am<br /><strong class="uppercase">{{vm.signer.name}}</strong><br />the signatory by drawing my signature.</em>
                                <div class="mt-5">
                                    <Button label="Luk / Close" class="p-button p-button-lg p-button-link mx-2" @click="vm.overlaySignPad=false" />
                                    <Button label="Indsend underskrift / Submit signature" class="p-button-success p-button-lg mx-2" @click="fn.confirmPadSign" :disabled="!eSignatureTouched" />
                                </div>
                            </div>
                        </div>
                        <div v-else class="text-center pb-5 w-full" style="max-width: 520px;user-select:none">
                            <h2 class="pb-4">Tegn din underskrift herunder</h2>
                            <e-signature :lang="vm.lang" :signerName="vm.signer.name" ref="eSignatureRef"></e-signature>
                            <div class="mt-4 mx-3" :style="{'visibility': eSignatureTouched ? 'visible' : 'hidden'}">
                                Ved at klikke "indsend underskrift", bekræfter jeg, at jeg er<br /><strong class="uppercase">{{vm.signer.name}}</strong><br />som har underskrevet ved at tegne min underskrift.
                            </div>
                            <div class="mt-5">
                                <Button label="Luk" class="p-button p-button-lg p-button-link mx-2" @click="vm.overlaySignPad=false" />
                                <Button label="Indsend underskrift" class="p-button-success p-button-lg mx-2" @click="fn.confirmPadSign" :disabled="!eSignatureTouched" />
                            </div>
                        </div>

                    </div>
                    <div v-if="vm.overlayDecline" class="overlay">
                        <div v-if="vm.lang==='en'" class="text-center pb-5" style="max-width: 490px;">
                            <h1 class="pb-2">{{vm.signer.name}}</h1>
                            <h4 class="pb-4">Are you sure you want to reject the document?<br />The document will be sealed and rejected.</h4>
                            <div class="mt-5">
                                <Button label="No" class="p-button p-button-lg p-button-link mx-2" @click="vm.overlayDecline=false" />
                                <Button label="Yes" class="p-button-danger p-button-lg mx-2" @click="fn.confirmDecline" />
                            </div>
                        </div>
                        <div v-else-if="vm.lang==='da_en'" class="text-center pb-5" style="max-width: 490px;">
                            <h1 class="pb-2">{{vm.signer.name}}</h1>
                            <h4 class="pb-2">Er du sikker på at du vil afvise dokumentet?<br />Hele dokumentet vil blive låst og afvist.</h4>
                            <h4 class="pb-4"><em class="secondary-language">Are you sure you want to reject the document?<br />The document will be sealed and rejected.</em></h4>
                            <div class="mt-5">
                                <Button label="Nej / No" class="p-button p-button-lg p-button-link mx-2" @click="vm.overlayDecline=false" />
                                <Button label="Ja / Yes" class="p-button-danger p-button-lg mx-2" @click="fn.confirmDecline" />
                            </div>
                        </div>
                        <div v-else class="text-center pb-5" style="max-width: 490px;">
                            <h1 class="pb-2">{{vm.signer.name}}</h1>
                            <h4 class="pb-4">Er du sikker på at du vil afvise dokumentet?<br />Hele dokumentet vil blive låst og afvist.</h4>
                            <div class="mt-5">
                                <Button label="Nej, fortryd" class="p-button p-button-lg p-button-link mx-2" @click="vm.overlayDecline=false" />
                                <Button label="Ja, afvis" class="p-button-danger p-button-lg mx-2" @click="fn.confirmDecline" />
                            </div>
                        </div>
                    </div>
                    <div v-if="vm.overlaySigned" class="overlay">
                        <div v-if="vm.lang==='en'" class="text-center pb-5" style="max-width: 490px;">
                            <SvgSuccess />
                            <h1 class="pb-2">Thank you for your signature</h1>
                            <h4 class="pb-4">It is now saved. If there are more people who need to sign, they can do so now or via the link they received in their mailbox.</h4>
                            <div class="mt-5">
                                <Button label="Ok" class="p-button-success p-button-lg mx-2" @click="vm.overlaySigned=false" />
                            </div>
                        </div>

                        <div v-else-if="vm.lang==='da_en'" class="text-center pb-5" style="max-width: 490px;">
                            <SvgSuccess />
                            <h1 class="pb-4">Tak for din underskrift<br /><em class="secondary-language">Thank you for your signature</em></h1>
                            <h4 class="pb-0">Din underskrift er nu registreret. Hvis der er andre, der skal underskrive dokumentet, kan de gøre det med det samme eller via det link, de har modtaget i deres e-mail.</h4>
                            <h4 class="pb-4"><em class="secondary-language">It is now saved. If there are more people who need to sign, they can do so now or via the link they received in their mailbox.</em></h4>
                            <div class="mt-5">
                                <Button label="Ok" class="p-button-success p-button-lg mx-2" @click="vm.overlaySigned=false" />
                            </div>
                        </div>

                        <div v-else class="text-center pb-5" style="max-width: 490px;">
                            <SvgSuccess />
                            <h1 class="pb-2">Tak for din underskrift</h1>
                            <h4 class="pb-4">Din underskrift er nu registreret. Hvis der er andre, der skal underskrive dokumentet, kan de gøre det med det samme eller via det link, de har modtaget i deres e-mail.</h4>
                            <div class="mt-5">
                                <Button label="Ok" class="p-button-success p-button-lg mx-2" @click="vm.overlaySigned=false" />
                            </div>
                        </div>
                    </div>
                    <div v-if="vm.overlayApproved" class="overlay">
                        <div v-if="vm.lang==='en'" class="text-center pb-5" style="max-width: 490px;">
                            <SvgSuccess />
                            <h1 class="pb-2">Thank you for your approval</h1>
                            <h4 class="pb-4">It is now saved. If there are more people who need to approve, they can do so now or via the link they have received in their mailbox.</h4>
                            <div class="mt-5">
                                <Button label="Ok" class="p-button-success p-button-lg mx-2" @click="vm.overlayApproved=false" />
                            </div>
                        </div>
                        <div v-else-if="vm.lang==='da_en'" class="text-center pb-5" style="max-width: 490px;">
                            <SvgSuccess />
                            <h1 class="pb-2">Tak for din godkendelse / Thank you for your approval</h1>
                            <h4 class="pb-4">Din underskrift er nu registreret. Hvis der er andre, der skal underskrive dokumentet, kan de gøre det med det samme eller via det link, de har modtaget i deres e-mail.</h4>
                            <h4 class="pb-4">It is now saved. If there are more people who need to approve, they can do so now or via the link they have received in their mailbox.</h4>
                            <div class="mt-5">
                                <Button label="Ok" class="p-button-success p-button-lg mx-2" @click="vm.overlayApproved=false" />
                            </div>
                        </div>
                        <div v-else class="text-center pb-5" style="max-width: 490px;">
                            <SvgSuccess />
                            <h1 class="pb-2">Tak for din godkendelse</h1>
                            <h4 class="pb-4">Din underskrift er nu registreret. Hvis der er andre, der skal underskrive dokumentet, kan de gøre det med det samme eller via det link, de har modtaget i deres e-mail.</h4>
                            <div class="mt-5">
                                <Button label="Ok" class="p-button-success p-button-lg mx-2" @click="vm.overlayApproved=false" />
                            </div>
                        </div>
                    </div>
                    <div v-if="vm.overlayConfirmMasterData" class="overlay overlay-attention">
                        <div v-if="vm.lang==='en'" class="text-center h-full pb-5" style="max-width: 620px;">
                            <h1 class="pb-2 pt-4">Confirm your data</h1>
                            <h4 class="pb-2">Please confirm that the information below is correct.</h4>
                            <confirm-member-data :data="vm.masterDataToConfirm" :lang="vm.lang" @confirm="(data) => {fn.confirmMasterData(data)}"></confirm-member-data>
                        </div>
                        <div v-else-if="vm.lang==='da_en'" class="text-center h-full pb-5" style="max-width: 620px;">
                            <h1 class="pb-2 pt-4">Bekræft dine data / Confirm your data</h1>
                            <h4 class="pb-2">Du bedes venligst bekræfte at nedenstående oplysninger er korrekte.</h4>
                            <h4 class="pb-2">Please confirm that the information below is correct.</h4>
                            <confirm-member-data :data="vm.masterDataToConfirm" :lang="vm.lang" @confirm="(data) => {fn.confirmMasterData(data)}"></confirm-member-data>
                        </div>
                        <div v-else class="text-center h-full pb-5" style="max-width: 620px;">
                            <h1 class="pb-2 pt-4">Bekræft dine data</h1>
                            <h4 class="pb-2">Du bedes venligst bekræfte at nedenstående oplysninger er korrekte.</h4>
                            <confirm-member-data :data="vm.masterDataToConfirm" :lang="vm.lang" @confirm="(data) => {fn.confirmMasterData(data)}"></confirm-member-data>
                        </div>
                    </div>
                    <div v-if="vm.submitting" class="overlay overlay-submitted flex-column">
                        <ProgressSpinner />
                        <h2 v-if="vm.lang==='en'" class="mt-5 mb-5">Please wait</h2>
                        <h2 v-else-if="vm.lang==='da_en'" class="mt-5 mb-5 text-center">Vent venligst<br /><em class="secondary-language">Please wait</em></h2>
                        <h2 v-else class="mt-5 mb-5">Vent venligst</h2>
                    </div>
                </div>
            </div>
            <div v-if="vm.view === 'student-grant-form'">
                <student-grant-form @submit="(data) => {fn.studentGrantFormSubmit(data)}" @upload-asset="(file) => {fn.uploadFileToDocument(file)}" :meta-data="vm.studentGrantMetaData"></student-grant-form>
            </div>
            <div v-if="vm.view === 'student-grant-form-error'" class="document">
                <div class="text-center" style="width:100%">
                    <h2>Statsstøtteskemaet kunne ikke hentes</h2>
                    <p>Det kan skyldes at linket er udløbet eller at skemaet er blevet slettet af skolen.<br /><br />Hvis I tidligere har udfyldt skemaet, behøver I ikke at gøre mere.<br /> Hvis I mener at det er en fejl, bedes I tage fat i afsender.</p>
                </div>
            </div>
            <div v-if="vm.view === 'invalid'" class="document">
                <div v-if="vm.lang==='en'" class="text-center" style="width:100%">
                    <h2 class="pb-5 pt-3">Document could not be retrieved</h2>
                    <p>If you have already completed the document, no further action is required.</p>
                    <p>If you believe this is an error, please contact the sender.</p>
                </div>
                <div v-else-if="vm.lang==='da_en'" class="text-center" style="width:100%">
                    <i class="pi pi-ban" style="color: red;font-size: 4rem;"></i>
                    <h2 class="pb-5 pt-3">Dokumentet kunne ikke hentes<br /><em class="secondary-language">Document could not be retrieved</em></h2>
                    <p>Hvis I allerede har udfyldt dokumentet, behøver I ikke at gøre mere.<br /> Hvis I mener, at dette er en fejl, bedes I tage fat i afsender.<br /><em class="secondary-language">If you have already completed the document, no further action is required.<br /> If you believe there has been a mistake, please check if you have received a reminder with a new link. If not, please contact the sender.</em></p>
                </div>
                <div v-else class="text-center" style="width:100%">
                    <i class="pi pi-ban" style="color: red;font-size: 4rem;"></i>
                    <h2 class="pb-5 pt-3">Dokumentet kunne ikke hentes</h2>
                    <p>Hvis I allerede har udfyldt dokumentet, behøver I ikke at gøre mere.</p>
                    <p>Hvis I mener, at dette er en fejl, bedes I tage fat i afsender.</p>
                </div>
            </div>
            <div v-if="vm.view === 'gone'" class="document">
                <div v-if="vm.lang==='en'" class="text-center mx-auto" style="width:100%;max-width:400px">
                    <i class="pi pi-history" style="color: red;font-size: 4rem;"></i>
                    <h2 class="pb-5 pt-3">The link is no longer valid</h2>
                    <p>Please check your inbox for a new email with an updated link.</p>
                    <p>If you have not received a new email, please contact the sender and request a new link.</p>
                </div>
                <div v-else-if="vm.lang==='da_en'" class="text-center mx-auto" style="width:100%;max-width:400px">
                    <i class="pi pi-history" style="color: red;font-size: 4rem;"></i>
                    <h2 class="pb-5 pt-3">Linket er ikke længere gyldigt<br /><em class="secondary-language">The link is no longer valid</em></h2>
                    <p>Vær venlig at tjekke din indbakke for en ny e-mail med et opdateret link. Hvis du ikke har modtaget en ny e-mail, bedes du kontakte afsenderen og anmode om et nyt link.<br /><em class="secondary-language">Please check your inbox for a new email with an updated link. If you have not received a new email, please contact the sender and request a new link.</em></p>
                </div>
                <div v-else class="text-center mx-auto" style="width:100%;max-width:400px">
                    <i class="pi pi-history" style="color: red;font-size: 4rem;"></i>
                    <h2 class="pb-5 pt-3">Linket er ikke længere gyldigt</h2>
                    <p>Vær venlig at tjekke din indbakke for en ny e-mail med et opdateret link.</p>
                    <p>Hvis du ikke har modtaget en ny e-mail, bedes du kontakte afsenderen og anmode om et nyt link.</p>
                </div>
            </div>
            <div v-if="vm.view === 'expired'" class="document">
                <div v-if="vm.lang==='en'" class="text-center mx-auto" style="width:100%;max-width:400px">
                    <i class="pi pi-history" style="color: red;font-size: 4rem;"></i>
                    <h2 class="pb-5 pt-3">The link has unfortunately expired</h2>
                    <p>Please contact the sender to receive a new one.</p>
                </div>
                <div v-else-if="vm.lang==='da_en'" class="text-center mx-auto" style="width:100%;max-width:400px">
                    <i class="pi pi-history" style="color: red;font-size: 4rem;"></i>
                    <h2 class="pb-5 pt-3">Linket er desværre udløbet<br /><em class="secondary-language">The link has unfortunately expired</em></h2>
                    <p>Kontakt venligst afsenderen for at få tilsendt et nyt.<br /><em class="secondary-language">Please contact the sender to receive a new one.</em></p>
                </div>
                <div v-else class="text-center mx-auto" style="width:100%;max-width:400px">
                    <i class="pi pi-history" style="color: red;font-size: 4rem;"></i>
                    <h2 class="pb-5 pt-3">Linket er desværre udløbet</h2>
                    <p>Kontakt venligst afsenderen for at få tilsendt et nyt.</p>
                </div>
            </div>
            <div class="pt-5 pb-3 text-center">


                <p v-if="vm.lang==='en'" style="color:#939393;font-size: 12px;padding-top: 2rem;">
                    We would like to inform you that the document may contain confidential information.<br />
                    If you have received an email with a link to this document by mistake, we kindly ask you to inform the sender about the error using the reply function.<br />
                    At the same time, we request that you delete the email from your system without forwarding or copying it.
                   
                </p>
                <p v-else style="color:#939393;font-size: 12px;padding-top: 2rem;">
                    Vi gør opmærksom på, at dokumentet kan indeholde fortrolige informationer.<br />
                    Hvis du ved en fejl har modtaget en e-mail med et link hertil, beder vi dig venligst om at informere afsenderen om fejlen ved hjælp af svar-funktionen.<br />
                    Samtidig beder vi dig om at slette e-mailen fra dit system uden at videresende eller kopiere den.
                </p>
                <div class="pt-3">
                    <a href="https://skoleadmin.dk">
                        <img src="../assets/skoleadmin-text-purple.svg" alt="skoleadmin.dk" style="max-width: 90px;" />
                    </a>
                </div>
                <p v-if="vm.lang==='en'" style="color:#939393;font-size: 12px;">
                    <a href="https://skoleadmin.dk/privatlivspolitik.pdf">Privacy Policy</a>
                </p>
                <p v-else style="color:#939393;font-size: 12px;">
                    <a href="https://skoleadmin.dk/privatlivspolitik.pdf">Privatlivspolitik</a>
                </p>
            </div>
        </div>
    </div>
</template>


<script setup>
    import { defineAsyncComponent, inject, ref, reactive, shallowRef, computed } from 'vue';
    import api from '@/composables/api';
    import { useRoute } from 'vue-router';
    import { useToast } from 'primevue/usetoast';
    import SvgSuccess from '@/components/SvgSuccess.vue';



    const apiUrl = inject('apiUrl');
    const route = useRoute();
    const documentTokenUrl = apiUrl + 'document/token';
    const documentTokenStudentGrantUrl = apiUrl + 'studentgrant/token';
    const eSignatureRef = ref(null);
    const eSignatureTouched = computed(() => eSignatureRef.value ? eSignatureRef.value.touched : false);
    const toast = useToast();

    const vm = reactive({
        conpanyInfo: {},
        apiHost: inject('apiHost'),
        busy: false,
        saved: false,
        submitting: false,
        error: false,
        view: null,
        documentId: null,
        memberId: null,
        currentMember: null,
        token: null,
        studentGrantToken: null,
        studentGrantMetaData: null,
        dynamicHtml: shallowRef(null),
        signer: null,
        masterData: null,
        masterDataToConfirm: null,
        signType: null,
        authorizationKey: null,
        showPincodeEntry: false
    });



    const fn = {
        requestCode: async function () {
            vm.busy = true;
            let url = apiUrl + 'authentication/requestAccessCode';
            let requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": route.query.aId
                },
                body: JSON.stringify({})
            };
            const response = await window.fetch(url, requestOptions);
            vm.busy = false;
            if (response.status === 200) {
                response.json().then((requestCodeResponse) => {
                    window.sessionStorage.setItem(vm.authorizationKey, requestCodeResponse.key);
                    vm.showPincodeEntry = true;
                    vm.authorizationKey = requestCodeResponse.key;
                    // Success message
                    let message = vm.lang === 'da' ? 'Du modtager en pinkode i din indbakke inden for de næste par minutter!' : 'You will receive a PIN code in your inbox within the next few minutes!';
                    toast.add({ severity: 'success', summary: message, life: 10000 });
                });
            } else {
                somethingWentWrong();
            }
            return true;
        },
        authenticate: async function () {
            vm.busy = true;

            var key = vm.authorizationKey;
            if (vm.accessInformation.requiresAccessCode && window.sessionStorage.getItem(vm.authorizationKey)) {
                key = window.sessionStorage.getItem(vm.authorizationKey);
            }

            let url = apiUrl + 'authentication/authenticate';
            let requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ key: key, code: vm.accessInformation.code })
            };
            const response = await window.fetch(url, requestOptions);
            vm.busy = false;
            if (!response.ok) {
                vm.authenticationFailure = true;
                return false;
            }
            vm.authenticationFailure = false;
            return true;
        },
        showDocument: async function () {
            const isAuthenticated = await fn.authenticate();
            vm.accessInformation.code = '';
            if (isAuthenticated) {
                if (vm.accessInformation.documentTemplateType === 1) {
                    getStudentGrantDocumentFromToken(null);
                } else {
                    vm.busy = true;
                    let url = apiUrl + 'documents';
                    let requestOptions = {
                        method: "POST",
                        headers: {
                            "Accept": "text/html",
                            "Content-Type": "multipart/form-data",
                            "Key": vm.authorizationKey,
                            "Action": 'view'
                        },
                        credentials: 'include',
                        //body: JSON.stringify({ key: vm.authorizationKey, action: 'view' })
                    };
                    const response = await window.fetch(url, requestOptions);
                    if (response.status === 200) {
                        response.text().then((markup) => {
                            vm.view = 'document';
                            vm.dynamicHtml = getAsyncComponent(markup);
                            setTimeout(function () {
                                var gridSignature = document.getElementById('grid-signature');
                                if (gridSignature) {
                                    gridSignature.scrollIntoView();
                                }
                            }, 100);
                        });
                    } else {
                        vm.view = 'access';
                    }
                    vm.busy = false;

                }
            }
        },
        save: function () {
            postAndUpdateDocumentWithToken('saveDraft', vm.authorizationKey);
        },
        saveAndReady: function () {
            postAndUpdateDocumentWithToken('save', vm.authorizationKey, null, () => {
                setTimeout(function () {
                    var gridSignature = document.getElementById('grid-signature');
                    if (gridSignature) {
                        document.getElementById('grid-signature').scrollIntoView();
                    }
                }, 100);

            });
        },
        confirmApprove: function () {
            if (vm.signer && vm.signer.id) {
                vm.overlayApprove = false;
                vm.signType = 'approve';
                postAndUpdateDocumentWithToken(
                    'approve', vm.authorizationKey,
                    { signerId: vm.signer.id },
                    function () { checkMasterDataStatus(vm.signer.id, signerHasCompleted); });
            }
        },
        confirmDecline: function () {
            if (vm.signer && vm.signer.id) {
                vm.overlayDecline = false;
                postAndUpdateDocumentWithToken('decline', vm.authorizationKey, { signerId: vm.signer.id });
            }
        },
        confirmPadSign: function () {
            if (eSignatureRef.value) {
                vm.overlaySignPad = false;
                vm.signType = 'padSign';
                var signatureData = eSignatureRef.value.toDataUrl().replace('data:', '').replace(/^.+,/, '');
                postAndUpdateDocumentWithToken(
                    'sign', vm.authorizationKey,
                    { signerId: vm.signer.id, signatureData: signatureData },
                    function () {
                        checkMasterDataStatus(vm.signer.id, signerHasCompleted);
                    }
                );
            } else {
                vm.error = 'E-signatur kunne ikke gemmes';
            }
        },
        confirmMasterData: async function (data) {
            vm.submitting = true;

            await updateSignerMasterData(vm.accessInformation.documentId, vm.signer.id, data);

            vm.masterDataToConfirm = null;
            vm.overlayConfirmMasterData = false;
            signerHasCompleted();
            vm.submitting = false;

        },
        studentGrantFormSubmit: function (data) {
            vm.busy = true;

            var formData = new FormData();
            formData.append('d', JSON.stringify(data));

            api.postFormData(documentTokenStudentGrantUrl + '/s/', formData).then(() => {
                getStudentGrantDocumentFromToken(vm.token);
            }).catch(() => {
                location.reload();
            });
        },
        uploadFileToDocument: function (file) {
            var formData = new FormData();

            formData.append('file', file);

            api.postFormData(documentTokenUrl + '/asset/', formData);
        },
    };

    const getStudentGrantDocumentFromToken = (token, isEditMode) => {
        var formData = new FormData();
        vm.busy = true;

        api.postFormData(documentTokenStudentGrantUrl + '/start', formData).then((response) => {
            if (response) {
                if (typeof response === 'object') {
                    if (response.inputCompleted && !isEditMode) {
                        api.postFormData(documentTokenStudentGrantUrl, formData).then((res) => {
                            vm.view = 'document';
                            vm.dynamicHtml = getAsyncComponent(res);
                            vm.busy = false;
                        });
                    } else {
                        vm.studentGrantMetaData = response;
                        if (vm.studentGrantMetaData.studentGrantData) {
                            vm.view = 'student-grant-form';
                        } else {
                            vm.view = 'student-grant-form-error';
                        }
                        vm.busy = false;
                    }
                } else {
                    vm.view = 'document';
                    vm.dynamicHtml = getAsyncComponent(response);
                    vm.busy = false;
                }
            }

        }).catch((error) => {
            vm.view = 'document'
            vm.busy = false;
            vm.error = error;
        });
    };
    const init = async () => {
        vm.busy = true;
        vm.authorizationKey = route.query.aId;
        const signerId = route.query.signerId;
        let url = apiUrl + 'documents/accessInformation';
        let requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": vm.authorizationKey
            },
            credentials: 'include'
        };
        const response = await window.fetch(url, requestOptions);
        if (response.status === 200) {
            response.json().then((accessInformation) => {
                if (accessInformation) {
                    vm.accessInformation = accessInformation;
                    vm.accessInformation.code = '';
                    vm.lang = accessInformation.documentTemplateLanguage;

                    // Is redirect from criipto? And Document not completed
                    if (signerId && accessInformation.documentState !== 6 && accessInformation.signedBy && accessInformation.signedBy.indexOf(signerId) !== -1) {
                        vm.signer = { id: signerId };
                        vm.view = 'document';
                        checkMasterDataStatus(signerId, signerHasCompleted);
                    } else {
                        //State 1=Sent,2=Draft, 3=ReadyToSign, 10=Signing
                        if (accessInformation.documentState < 4 || accessInformation.documentState === 10) {
                            if (accessInformation.requiresAccessCode && window.sessionStorage.getItem(vm.authorizationKey)) {
                                vm.showPincodeEntry = true;
                            }
                            vm.view = 'access';

                        } else {
                            somethingWentWrong();
                        }
                    }
                } else {
                    somethingWentWrong();
                }
                vm.busy = false;
            });
        } else if (response.status === 410) {
            response.json().then((res) => {
                if (res.message === 'Obsolete') {
                    vm.view = 'gone';
                } else if (res.message === 'Expired') {
                    vm.view = 'expired';
                } else {
                    somethingWentWrong();
                }
            });
            vm.busy = false;

        } else {
            somethingWentWrong();
        }
    };

    init();


    // Private functions
    function validateForm(event, form, callback) {
        if (form.checkValidity()) {
            let error = false;
            const formData = new FormData(form);
            const allRequiredCheckboxGroups = document.getElementsByClassName('question-required');

            for (var i = 0, l = allRequiredCheckboxGroups.length; i < l; i++) {
                const firstGroupItem = allRequiredCheckboxGroups[i].getElementsByTagName('input')[0];
                firstGroupItem.closest('.question-required').classList.remove('invalid');
                if (!formData.has(firstGroupItem.name)) {
                    error = true;
                    firstGroupItem.closest('.question-required').classList.add('invalid');
                }
            }

            if (!error && callback) {
                callback();
            }

            event.preventDefault();
        }
    }
    async function postAndUpdateDocumentWithToken(apiUrlAction, key, data, callback) {
        vm.submitting = true;

        //Formdata
        var form = document.querySelector('form');
        var formData = form && form != null ? new FormData(form) : new FormData();

        // Has masterdata?
        if (vm.masterData) {
            formData.append('masterData', JSON.stringify(vm.masterData));
        }

        if (data) {
            if (data.signerId) {
                formData.append('sid', data.signerId);
            }
            if (data.signatureData) {
                formData.append('sda', data.signatureData);
            }
            if (data.blob) {
                formData.append('file', data.blob);
            }
        }

        let requestOptions = {
            method: "POST",
            headers: {
                "Accept": "text/html",

                "Key": key,
                "Action": apiUrlAction
            },
            credentials: 'include',
            body: formData
        };

        let url = apiUrl + 'documents';
        const response = await window.fetch(url, requestOptions);

        if (response.status === 200) {
            response.text().then((markup) => {
                vm.dynamicHtml = getAsyncComponent(markup);
                vm.submitting = false;

                if (callback) {
                    callback();
                }

                // if document html has element with id go-to-top
                // scroll it into view
                setTimeout(function () {
                    var goToTop = document.getElementById('go-to-top');
                    if (goToTop) {
                        goToTop.scrollIntoView();
                    }
                }, 100);
            });
        }
        else {
            window.location.reload();
        }
    }
    async function getSigner(documentId, id) {
        let url = apiUrl + 'documents/' + documentId + '/signers/' + id;
        let requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: 'include'
        };
        const response = await window.fetch(url, requestOptions);

        if (response.status === 200) {
            return await response.json();
        } else {
            toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: response.errors && response.errors[0].message });
            return null;
        }
    }
    async function updateSignerMasterData(documentId, id, masterData) {
        let url = `${apiUrl}documents/${documentId}/signers/${id}/masterdata`;

        let requestOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(masterData),
            credentials: 'include'
        };
        const response = await window.fetch(url, requestOptions);


        if (response.status === 200) {
            return true;
        } else {
            toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: response.errors[0].message });
            return false;
        }
    }
    function setMasterData(data) {
        vm.masterData = data;
    }
    async function checkMasterDataStatus(signerId, callback) {
        vm.submitting = true;
        const signer = await getSigner(vm.accessInformation.documentId, signerId);
        if (signer.confirmMasterData && !signer.masterDataConfirmed) {
            vm.submitting = false;
            vm.masterDataToConfirm = signer.masterData;
            vm.overlayConfirmMasterData = true;
        } else {
            vm.submitting = false;
            if (!signer.masterDataConfirmed && callback) {
                callback();
            }
        }
    }
    function getAsyncComponent(markup) {
        return defineAsyncComponent(() => {
            return new Promise((resolve) => {
                resolve({
                    template: markup,
                    data() {
                        return {
                            overlay: true
                        }
                    },
                    components: {
                        SvgSuccess 
                    },
                    methods: {
                        comp_saveAndReady: function (e) {
                            validateForm(e, document.querySelector('form'), fn.saveAndReady);
                        },
                        comp_save: function (e) {
                            e.preventDefault();
                            fn.save();
                        },
                        comp_approve: function (signerId, signerName) {
                            vm.signer = {
                                id: signerId,
                                name: signerName
                            };
                            vm.overlayApprove = true;
                        },
                        comp_signPad: function (signerId, signerName) {
                            vm.signer = {
                                id: signerId,
                                name: signerName
                            };
                            vm.overlaySignPad = true;
                        },
                        comp_signDigitally: function (url) {
                            if (url) {
                                vm.busy = true;
                                window.location.href = url;
                            }
                        },
                        comp_decline: function (signerId, signerName) {
                            vm.signer = {
                                id: signerId,
                                name: signerName
                            };
                            vm.overlayDecline = true;
                        },
                        comp_close_overlay: function () {
                            this.overlay = false
                        },
                        comp_edit: function () {
                            if (vm.accessInformation && vm.accessInformation.documentTemplateType === 1) {
                                getStudentGrantDocumentFromToken(vm.token, true);
                            } else {
                                postAndUpdateDocumentWithToken('edit', vm.authorizationKey);
                            }
                        },
                        comp_setMasterdata: function (data) {
                            setMasterData(data);
                        }
                    }
                })
            })
        });
    }
    async function signerHasCompleted() {
        vm.signer = null;

        if (vm.signType === 'approve') {
            vm.overlayApproved = true;
        } else {
            vm.overlaySigned = true;

            if (vm.signType === null) {
                await fn.showDocument();
            }
        }
    }
    function somethingWentWrong() {
        vm.busy = false;
        vm.view = 'invalid';
    }


</script>
