<template>
    <div class="home">
        <div v-if="vm.view === 'overview'">
            <div class="content-wrapper" v-if="vm.busy">
                <div class="main-content text-center">
                    <ProgressSpinner />
                </div>
            </div>
            <div class="content-wrapper" v-else>
                <div class="main-content mt-0">
                    <!--<Message severity="info" :closable="false" class="mt-0 mb-2">
                        Hold dig opdateret med de nyeste tips og opdateringer fra Skoleadmin! Følg os på <a href="https://facebook.com/skoleadmin" target="_blank">Facebook</a> og <a href="https://www.linkedin.com/company/skoleadmin/" target="_blank">LinkedIn</a>
                    </Message>-->
                    <Message :closable="false" severity="info" class="mt-0 mb-3">
                        <strong>
                            Nu kan du selv styre brugere i Skoleadmin (Udgivet den 04.12.24)
                        </strong><br />
                        <router-link :to="{name:'AdminReleases'}">Læs om de seneste ændringer her</router-link> - Hvis I har forslag eller mangler noget i Skoleadmin, er I meget velkomne til at kontakte os på <a href="mailto:hej@skoleadmin.dk">hej@skoleadmin.dk</a>.
                    </Message>
                    <div class="grid">
                        <div class="col-12 text-center" v-if="vm.expenseStats">
                            <div class="box flex justify-content-between">
                                <div class="flex align-items-center">
                                    <i class="pi pi-wallet mr-3 ml-2" style="font-size: 2.5rem;color: rgb(59, 130, 246);"></i>
                                    <div class="mx-3">
                                        <h3 class="text-left m-0"><strong>Forbrug</strong></h3>
                                        <div class="secondary-text" style="font-size: 14px;"><strong>Underskrifter</strong></div>
                                    </div>
                                </div>
                                <div class="flex text-center">
                                    <div class="mx-3" v-if="vm.expenseStats.criiptoSignature.price">
                                        <div style="font-family: 'Figtree', sans-serif; font-size: 2rem; font-weight: bold;">{{vm.expenseStats.criiptoSignature.quantity}}</div>
                                        <div class="secondary-text" style="font-size: 14px;"><strong>MitID</strong></div>
                                    </div>
                                    <div class="mx-3" v-if="vm.expenseStats.padSignSignature.price">
                                        <div style="font-family:'Figtree', sans-serif;font-size: 2rem;font-weight: bold;">{{vm.expenseStats.padSignSignature.quantity}}</div>
                                        <div class="secondary-text" style="font-size: 14px;"><strong>Touch/mus</strong></div>
                                    </div>
                                    <div class="mx-3" v-if="vm.expenseStats.approveSignature.price">
                                        <div style="font-family:'Figtree', sans-serif;font-size: 2rem;font-weight: bold;">{{vm.expenseStats.approveSignature.quantity}}</div>
                                        <div class="secondary-text" style="font-size: 14px;"><strong>Klik</strong></div>
                                    </div>
                                    <div class="ml-6 mr-2">
                                        <div style="font-family: 'Figtree', sans-serif; font-size: 2rem; font-weight: bold;"><span class="mr-1" style="font-size: 16px;">{{vm.expenseStats.currency}}</span>{{totalExpenses}}</div>
                                        <div class="secondary-text" style="font-size: 14px;"><strong>I alt (inkl. moms)</strong></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-5">
                            <div v-if="vm.latestDocuments && vm.latestDocuments.length > 0">
                                <h4>Svarprocent <span class="secondary-text">(seneste afsendte dokumenter)</span></h4>
                                <div class="grid">
                                    <div class="col-12 md:col-6 text-center" :class="{'xxl:col-3' : vm.latestDocuments.length > 3, 'xxl:col-4': vm.latestDocuments.length === 3}" v-for="document in vm.latestDocuments" :key="document.id">
                                        <div class="stats-box clickable p-2" @click="fn.documentDetails(document.id, document.name)">
                                            <div class="text-center">
                                                <stats-half-doughnut :total="document.totalSentTo" :completed="document.totalCompleted" :declined="document.totalDeclined" />
                                                <h5 class="inline-flex align-items-center mx-2">{{document.name}}</h5>
                                                <div style="color:initial">
                                                    <p v-if="document.totalSentTo === 1" class="secondary-label">{{document.totalSentTo}} afsendt</p>
                                                    <p v-else class="secondary-label">{{document.totalSentTo}} afsendte</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div v-if="vm.receivingdocumentStatistics" class="text-center">
                                    <ProgressSpinner />
                                </div>
                                <p v-else class="box">Du har endnu ikke sendt nogen dokumenter. Er du i tvivl om, hvordan du kommer videre? Tag et kig på vores <a href="https://skoleadmin.dk/start.pdf" target="_blank">"Kom godt i gang"-guide</a>.</p>
                            </div>
                        </div>
                        <div class="col-12 mt-5" v-if="vm.readyToApproveDocuments && vm.readyToApproveDocuments.length > 0">
                            <div>
                                <h4 class="mb-0">Afventer godkendelse</h4>
                                <DataTable stripedRows
                                           :value="vm.readyToApproveDocuments"
                                           dataKey="id"
                                           sortField="updated" :sortOrder="-1"
                                           responsiveLayout="scroll"
                                           :paginator="vm.readyToApproveDocumentsShowPaginator" :rows="10" :filters="filters"
                                           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50]"
                                           currentPageReportTemplate="Viser {first} til {last} af {totalRecords}">
                                    <Column header="Skabelon navn" field="documentTemplate.name" headerClass="pl-0"></Column>
                                    <Column header="Elev" field="owner.firstname" sortable>
                                        <template #body="slotProps">
                                            {{slotProps.data.owner.firstname}} {{slotProps.data.owner.lastname}}
                                        </template>
                                    </Column>
                                    <Column header="Sidst opdateret" field="updated" sortable>
                                        <template #body="slotProps">
                                            {{$filters.formatDate(slotProps.data.updated)}}
                                        </template>
                                    </Column>
                                    <Column style="width:208px;min-width:208px;padding-top:0;padding-bottom:0;padding-right:4px" frozen alignFrozen="right" class="text-right column-controls">
                                        <template #body="slotProps">
                                            <ProgressSpinner v-if="vm.documentWithIdIsBusy === slotProps.data.id" />
                                            <div v-else>
                                                <Button icon="pi pi-check" class="p-button-success" @click="fn.approveDocument(slotProps.data.id)" v-tooltip.top="'Godkend'"></Button>
                                                <Button icon="pi pi-times" class="p-button-danger mx-2" @click="fn.declineDocument(slotProps.data.id)" v-tooltip.top="'Afvis'"></Button>
                                                <Button icon="pi pi-eye" class="ml-3" @click="fn.goToDocument(slotProps.data.id)" v-tooltip.top="'Se dokument'"></Button>
                                            </div>
                                        </template>
                                    </Column>
                                </DataTable>
                            </div>
                        </div>
                        <div class="col-12 lg:col-6 mt-5" v-if="vm.latestDocuments && vm.latestDocuments.length > 0">
                            <div>
                                <h4 class="mb-0">Afviste dokumenter <span v-if="vm.declinedDouments.length" class="secondary-text">({{vm.declinedDouments.length}} stk)</span></h4>
                                <DataTable v-if="vm.declinedDouments && vm.declinedDouments.length"
                                           stripedRows
                                           :value="vm.declinedDouments"
                                           dataKey="id"
                                           sortField="updated" :sortOrder="-1"
                                           :paginator="vm.declinedDouments && vm.declinedDouments.length > 5"
                                           responsiveLayout="scroll"
                                           :rows="5">
                                    <Column header="Skabelon navn" headerClass="pl-0">
                                        <template #body="slotProps">
                                            <strong class="clickable" @click="fn.documentDetails(slotProps.data.documentTemplate.id, slotProps.data.documentTemplate.name)">{{slotProps.data.documentTemplate.name}}</strong>
                                        </template>
                                    </Column>
                                    <Column header="Elev">
                                        <template #body="slotProps">
                                            {{slotProps.data.owner.firstname}} {{slotProps.data.owner.lastname}}
                                        </template>
                                    </Column>
                                    <Column field="createdDate" header="Afvist" headerStyle="width:115px">
                                        <template #body="slotProps">
                                            <div v-html="$filters.formatDateHtml(slotProps.data.updated)"></div>
                                        </template>
                                    </Column>

                                </DataTable>
                                <div v-else>
                                    <div class="p-datatable p-component p-datatable-responsive-scroll">
                                        <table role="table" class="p-datatable-table">
                                            <thead class="p-datatable-thead" role="rowgroup"><tr><th class="pl-0" role="cell"><div class="p-column-header-content"><span class="p-column-title">Skabelon navn</span></div></th><th class="" role="cell"><div class="p-column-header-content"><span class="p-column-title">Elev</span></div></th><th class="" role="cell" style="width: 115px;"><div class="p-column-header-content"><span class="p-column-title">Afvist</span></div></th></tr></thead>
                                            <tbody class="p-datatable-tbody" role="rowgroup"><tr><td style="border-radius: 12px; height: 77px;" colspan="3"><div class="flex align-items-center"><i style="font-size:20px" class="pi pi-info-circle mr-2"></i>Ingen dokumenter afvist</div></td></tr></tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 lg:col-6 mt-5" v-if="vm.latestDocuments && vm.latestDocuments.length > 0">
                            <div>
                                <h4 class="mb-0">Afviste samtykker <span v-if="vm.declinedConsents.length" class="secondary-text">({{vm.declinedConsents.length}} stk)</span></h4>
                                <DataTable v-if="vm.declinedConsents && vm.declinedConsents.length"
                                           stripedRows
                                           :value="vm.declinedConsents"
                                           dataKey="id"
                                           sortField="updated" :sortOrder="-1"
                                           responsiveLayout="scroll"
                                           :paginator="vm.declinedConsents && vm.declinedConsents.length > 5"
                                           :rows="5">
                                    <Column header="Samtykke" headerClass="pl-0">
                                        <template #body="slotProps">
                                            <strong class="clickable" @click="fn.goToConsentDetails(slotProps.data.consentId)">{{slotProps.data.consent.name}}</strong>
                                        </template>
                                    </Column>
                                    <Column header="Elev">
                                        <template #body="slotProps">
                                            {{slotProps.data.member.firstname}} {{slotProps.data.member.lastname}}
                                        </template>
                                    </Column>
                                    <Column header="Afvist" headerStyle="width:115px">
                                        <template #body="slotProps">
                                            <div v-html="$filters.formatDateHtml(slotProps.data.updated)"></div>
                                        </template>
                                    </Column>

                                </DataTable>
                                <div v-else>
                                    <div class="p-datatable p-component p-datatable-responsive-scroll">
                                        <table role="table" class="p-datatable-table">
                                            <thead class="p-datatable-thead" role="rowgroup"><tr><th class="pl-0" role="cell"><div class="p-column-header-content"><span class="p-column-title">Samtykke</span></div></th><th class="" role="cell"><div class="p-column-header-content"><span class="p-column-title">Elev</span></div></th><th class="" role="cell" style="width: 115px;"><div class="p-column-header-content"><span class="p-column-title">Afvist</span></div></th></tr></thead>
                                            <tbody class="p-datatable-tbody" role="rowgroup"><tr><td style="border-radius: 12px; height: 77px;" colspan="3"><div class="flex align-items-center"><i style="font-size:20px" class="pi pi-info-circle mr-2"></i>Ingen samtykker afvist</div></td></tr></tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-6" v-if="vm.bouncedEmails && vm.bouncedEmails.length > 0">
                        <div class="flex flex-column md:flex-row md:justify-content-between">
                            <h4 class="flex-grow-1">Fejl i afsendelse</h4>
                        </div>
                        <DataTable :value="vm.bouncedEmails"
                                   dataKey="id"
                                   sortField="createdDate" :sortOrder="-1"
                                   :paginator="vm.bouncedEmails.length > 5" :rows="5" :filters="filters"
                                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                                   currentPageReportTemplate="Viser {first} til {last} af {totalRecords} afsendte dokumenter" responsiveLayout="scroll">
                            <Column header="Elev" headerClass="pl-0" headerStyle="width:280px">
                                <template #body="slotProps">
                                    <div class="align-items-center flex">
                                        <i v-if="slotProps.data.emailHasBounced" class="pi pi-times-circle mr-3" style="color: #EF4444;font-size:22px"></i>
                                        <div>
                                            <div>{{slotProps.data.firstname}} {{slotProps.data.lastname}}</div>
                                            <div class="text-sm">{{slotProps.data.email}}</div>
                                        </div>
                                    </div>
                                </template>
                            </Column>
                            <Column header="Værge" headerStyle="width:280px">
                                <template #body="slotProps">
                                    <div v-for="memberRelation in slotProps.data.memberRelations" :key="memberRelation.relatedMember.id">
                                        <div class="align-items-center flex" v-if="memberRelation.relatedMember.emailHasBounced">
                                            <i class="pi pi-times-circle mr-3" style="color: #EF4444;font-size:22px"></i>
                                            <div>
                                                <div>{{memberRelation.relatedMember.firstname}} {{memberRelation.relatedMember.lastname}}</div>
                                                <div class="text-sm">{{memberRelation.relatedMember.email}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </Column>
                            <Column header="Fejlbesked" class="text-right" headerClass="column-last">
                                <template #body="slotProps">
                                    <div v-if="slotProps.data.emailHasBounced" class="font-italic text-sm">
                                        {{slotProps.data.emailBounceStatus}}: {{slotProps.data.emailBounceMessage}}
                                    </div>
                                    <div v-for="memberRelation in slotProps.data.memberRelations" :key="memberRelation.relatedMember.id">
                                        <span v-if="memberRelation.relatedMember.emailHasBounced" class="font-italic text-sm">
                                            {{memberRelation.relatedMember.emailBounceStatus}}: {{memberRelation.relatedMember.emailBounceMessage}}
                                        </span>
                                    </div>
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                    <div class="mt-6" v-if="vm.batches && vm.batches.length">
                        <div class="flex flex-column md:flex-row md:justify-content-between">
                            <h4 class="flex-grow-1">Afsendelseslog <span class="secondary-text">(seneste 30 dage)</span></h4>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Søg..." style="width:100%" />
                            </span>
                        </div>

                        <DataTable :value="vm.batches"
                                   :loading="vm.receivingLogData"
                                   dataKey="id"
                                   sortField="createdDate" :sortOrder="-1"
                                   :paginator="vm.batches && vm.batches.length > 10" :rows="10" :filters="filters"
                                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                                   currentPageReportTemplate="Viser {first} til {last} af {totalRecords} afsendte dokumenter" responsiveLayout="scroll">
                            <Column field="documentName" header="Skabelon navn" :sortable="true" headerClass="pl-0">
                                <template #body="slotProps">
                                    <strong class="clickable" @click="fn.documentDetails(slotProps.data.documentId)">{{slotProps.data.documentName}}</strong>
                                </template>
                            </Column>
                            <Column field="createdDate" header="Afsendt" :sortable="true" headerStyle="width:200px">
                                <template #body="slotProps">
                                    {{$filters.formatDate(slotProps.data.createdDate)}}
                                    <!--<div v-html="$filters.formatDateHtml(slotProps.data.createdDate)"></div>-->
                                </template>
                            </Column>
                            <Column header="Status" headerClass="column-last" :showFilterMatchModes="false" style="min-width: 10rem">
                                <template #body="slotProps">
                                    <ProgressBar :value="fn.toFixed(((slotProps.data.totalCompleted+slotProps.data.totalDeclined)/slotProps.data.totalSentTo)*100)" :showValue="true" />
                                    <small class="block mt-1 text-right">{{slotProps.data.totalCompleted+slotProps.data.totalDeclined}} ud af {{slotProps.data.totalSentTo}} modtager(e) har svaret</small>
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { inject, reactive, ref, computed } from 'vue';
    import { FilterMatchMode } from 'primevue/api';
    import { useToast } from 'primevue/usetoast';
    import { useRouter } from 'vue-router';
    import { useConfirm } from "primevue/useconfirm";
    import api from './../../composables/api';
    import gqlBuilder from './../../composables/gql-builder';


    const store = inject('store');
    const apiUrl = inject('apiUrl');
    const toast = useToast();
    const router = useRouter();
    const confirm = useConfirm();
    const filters = ref({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const vm = reactive({
        view: 'overview',
        latestDocuments: [],
        batches: [],
        declinedDouments: [],
        declinedConsents: [],
        expenseStats: null,
        busy: true,
        receivingLogData: true,
        receivingdocumentStatistics: true,
        documentWithIdIsBusy: null,
        readyToApproveDocuments: [],
        readyToApproveDocumentsShowPaginator: false,
        customerConfiguration: null
    });
    const totalExpenses = computed(() => {
        return new Intl.NumberFormat('da-dk', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(vm.expenseStats.criiptoSignature.total + vm.expenseStats.padSignSignature.total + vm.expenseStats.approveSignature.total);
    })
    const fn = {
        toFixed: (value) => {
            return parseInt(value.toFixed());
        },
        documentDetails: (id) => {
            router.push({ name: 'AdminTemplateStats', params: { id: id } });
        },
        goToConsentDetails: (consentId) => {
            router.push({ name: 'AdminConsents', params: { id: consentId } });
        },
        goToDocument: (documentId) => {
            if (documentId) {
                window.open('/document/' + documentId, '_blank');
            }
        },
        approveDocument: (documentId) => {
            vm.documentWithIdIsBusy = documentId;
            api.get(apiUrl + 'customer/' + store.auth.customerId + '/document/' + documentId + '/accept').then(() => {
                vm.readyToApproveDocuments = vm.readyToApproveDocuments.filter(d => d.id != documentId);
                vm.documentWithIdIsBusy = null;
                toast.add({ severity: 'success', summary: 'Dokument godkendt', life: 4000 });

            }).catch((error) => {
                vm.documentWithIdIsBusy = null;
                vm.error = error;
            });
        },
        declineDocument: (documentId) => {
            confirm.require({
                message: 'Er du sikker på at du vil afvise dokumentet?',
                header: 'Bekræft venligst',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Ja, afvis',
                rejectLabel: 'Nej',
                accept: () => {
                    vm.documentWithIdIsBusy = documentId;
                    api.get(apiUrl + 'customer/' + store.auth.customerId + '/document/' + documentId + '/decline').then(() => {
                        vm.readyToApproveDocuments = vm.readyToApproveDocuments.filter(d => d.id != documentId);
                        vm.documentWithIdIsBusy = null;

                    }).catch((error) => {
                        vm.documentWithIdIsBusy = null;
                        vm.error = error;
                    });
                }
            });
        }
    };

    // Get all sent log
    api.query(gqlBuilder.batches.get(store.auth.customerId)).then((response) => {
        if (api.hasData(response)) {
            vm.batches = api.getData(response);
            vm.receivingLogData = false;
        }
    }).catch((error) => {
        toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: error });
    });

    // Get latest document template stats
    api.query(gqlBuilder.documentTemplates.getStatsForLatestDocumentTemplates(store.auth.customerId)).then((response) => {
        if (api.hasData(response)) {
            vm.latestDocuments = api.getData(response);
        }
        vm.receivingdocumentStatistics = false;
    });


    // Ready to approve documents
    api.query(gqlBuilder.customer.getDocumentsReadyToSenderApproveByCustomerId(store.auth.customerId)).then((response) => {
        if (api.hasData(response)) {
            vm.readyToApproveDocuments = api.getData(response);
            vm.readyToApproveDocumentsShowPaginator = vm.readyToApproveDocuments.length > 10;
        }
        vm.busy = false;
    });

    // Expense log
    api.query(gqlBuilder.customer.getExpenseStats(store.auth.customerId)).then((response) => {
        if (api.hasData(response)) {
            vm.expenseStats = api.getData(response);
        }
    });

    // Declined documents
    api.query(gqlBuilder.customer.getDeclinedDocuments(store.auth.customerId)).then((response) => {
        if (api.hasData(response)) {
            vm.declinedDouments = api.getData(response);
        }
        vm.busy = false;
    });

    //Declined consents
    api.query(gqlBuilder.customer.getDeclinedConsents(store.auth.customerId)).then((response) => {
        if (api.hasData(response)) {
            vm.declinedConsents = api.getData(response);
        }
        vm.busy = false;
    });

    //Bounced members
    api.query(gqlBuilder.memberGroups.getMembersWithBouncedEmail(store.auth.customerId)).then((response) => {
        if (api.hasData(response)) {
            vm.bouncedEmails = api.getData(response);
        }
    });

</script>
<style>
    .home .stats-box {
        background: white;
        border-radius: 15px;
        padding: 1rem 0;
        height: 100%;
        overflow: hidden;
    }
</style>