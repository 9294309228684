<template>
  <div v-if="vm.initializing" style="margin: 100px auto 0" class="loader-wrapper">
    <ProgressSpinner />
  </div>
  <router-view v-else />
  <div v-if="vm.apiDown" class="text-center p-4 p-100">
    <div class="pt-5 pb-3">
      <a href="https://skoleadmin.dk">
        <img src="./assets/skoleadmin-text-purple.svg" alt="skoleadmin.dk" style="max-width: 120px;" />
      </a>
    </div>
    <h2>Sitet er midlertidigt nede</h2>
    <p>Prøv igen om lidt</p>
  </div>
  <Toast />
  <ConfirmDialog />
</template>

<script setup>
    import { inject, reactive } from 'vue';
    import api from './composables/api';
    import { useRouter } from 'vue-router';

    const router = useRouter();
    const rootUrl = inject('rootUrl');
    const apiUrl = inject('apiUrl');
    const store = inject('store');

    const vm = reactive({
        initializing: false,
        apiDown: false
    });


    router.beforeEach(async (to, from, next) => {
        if (to.meta.requiresSystemUserAuth) {
            try {
                const response = await api.get(apiUrl + 'authentication/IsAuthenticated');

                if (!response.isAuthenticated) {
                    const returnUrl = encodeURIComponent(rootUrl + to.path);
                    if (to.meta.requiresSystemUserAuth) {
                        window.location.href = apiUrl + 'authentication/authenticate?returnUrl=' + returnUrl;
                    } else {
                        router.push({ name: 'Login', query: { returnUrl } });
                    }
                } else if (response.unknownUser) {
                    const returnUrl = encodeURIComponent(rootUrl + '?unknownUser=true');
                    window.location.href = apiUrl + 'authentication/SignOut?returnUrl=' + returnUrl;
                } else {
                    // Set customer ID
                    if (response.customerIds?.length > 0) {
                        store.setCustomerId(response.customerIds[0]);
                        store.setCurrentSystemUserId(response.systemUserIds[0]);
                        store.setAuthenticated(response.isAuthenticated);
                    }

                    // Get customer configuration if it hasn't been fetched yet
                    if (!store.config.configFetched) {
                        const configResponse = await api.get(`${apiUrl}customers/${store.auth.customerId}/configurations`);

                        if (configResponse) {
                            store.setPartnerName(configResponse.partnerName || null); // null if empty
                            store.setPartnerLogoUrl(configResponse.partnerLogoUrl || null); // null if empty
                            store.setEnableAliasOnGridItems(configResponse.enableAliasOnGridItems);
                            store.setEnableStudentGrantModule(configResponse.enableStudentGrantModule);
                            store.setMaxUsers(configResponse.maxUsers);
                            store.setConfigFetched();
                        }
                    }

                    // Handle system user authentication
                    if (to.meta.requiresSystemUserAuth) {
                        if (store.auth.customerId) {
                            if (!store.auth.currentUser.id) {
                                const userResponse = await api.get(`${apiUrl}systemusers/${response.systemUserIds[0]}`);
                                if (userResponse) {
                                    store.setCurrentUser(api.getData(userResponse));
                                }
                            }
                        } else {
                            router.push({ name: 'Start' });
                        }
                    }

                    next();
                }
            } catch (error) {
                vm.apiDown = true;
            }
        } else {
            next();
        }
    });

</script>

<style lang="scss">
@import './styling/main';
</style>
