import { reactive, readonly } from "vue";

// Authentication-related state
const state = reactive({
    isAuthenticated: false,
    currentSystemUserId: '',
    currentUser: {},
    customerId: null
});

// Config-related state
const config = reactive({
    partnerName: null,
    partnerLogoUrl: null,
    enableAliasOnGridItems: false,
    enableStudentGrantModule: false,
    configFetched: false,
    maxUsers: null
});

// State mutation functions
const setState = {
    setAuthenticated: (isAuthenticated) => (state.isAuthenticated = isAuthenticated),
    setCurrentSystemUserId: (id) => (state.currentSystemUserId = id),
    setCurrentUser: (user) => (state.currentUser = user),
    setCustomerId: (id) => (state.customerId = id),
    reset: () => {
        state.isAuthenticated = false;
        state.currentSystemUserId = '';
        state.currentUser = {};
        state.customerId = null;
    }
};

// Config mutation functions
const setConfig = {
    setPartnerName: (name) => (config.partnerName = name),
    setPartnerLogoUrl: (url) => (config.partnerLogoUrl = url),
    setEnableAliasOnGridItems: (value) => (config.enableAliasOnGridItems = value),
    setEnableStudentGrantModule: (value) => (config.enableStudentGrantModule = value),
    setMaxUsers: (value) => (config.maxUsers = value),
    setConfigFetched: () => (config.configFetched = true), 
    resetConfig: () => {  
        config.partnerName = null;
        config.partnerLogoUrl = null;
        config.enableAliasOnGridItems = false;
        config.enableStudentGrantModule = false;
        config.configFetched = false;  
    }
};


export default {
    auth: readonly(state),
    config: readonly(config),
    ...setState,
    ...setConfig
};
