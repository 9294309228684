<template>
    <div class="page-admin-users">
        <page-header headline="Brugere">
            <Button label="Opret ny bruger" icon="pi pi-plus" class="p-button-success" @click="fn.showSidebar()" :disabled="isCreateButtonDisabled" />
        </page-header>
        <div class="content-wrapper">
            <div class="main-content" v-if="vm.initialized">
                <Message severity="info" :closable="false" class="mt-0 mb-5">
                    Det er muligt at oprette op til {{store.config.maxUsers}} brugere. Kontakt os venligst, hvis I har behov for at oprette flere brugere.
                </Message>
                <div v-if="vm.items.length > 0">
                    <DataTable stripedRows
                               :value="vm.items"
                               dataKey="id"
                               sortField="updated" :sortOrder="1"
                               :filters="filters"
                               responsiveLayout="scroll">
                        <Column field="firstname" header="Navn" sortable>
                            <template #body="slotProps">
                                <div class="flex p-link clickable" @click="fn.showSidebar(slotProps.data, true)">
                                    <strong>{{ slotProps.data.firstname }} {{ slotProps.data.lastname }}</strong>
                                </div>
                            </template>
                        </Column>
                        <Column field="email" header="E-mail" sortable></Column>
                        <Column field="status" header="Status" sortable class="text-center column-state" style="width:125px">
                            <template #body="slotProps">
                                <Tag v-if="slotProps.data.status === 0" value="Oprettet" severity="info"></Tag>
                                <Tag v-else-if="slotProps.data.status === 1" value="Inviteret" severity="info"></Tag>
                                <Tag v-else-if="slotProps.data.status === 2" value="Aktiv" severity="success"></Tag>
                                <Tag v-else-if="slotProps.data.status === 3" value="Låst" severity="warning"></Tag>
                                <Tag v-else-if="slotProps.data.status === 4" value="Deaktiveret"></Tag>
                                <Tag v-else severity="info">Ukendt</Tag>
                            </template>
                        </Column>
                        <Column :exportable="false" class="text-center" style="width:80px">
                            <template #body="slotProps">
                                <Button type="button" icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text mr-2 py-0 h-2rem w-2rem" aria-haspopup="true" :aria-controls="'overlay_menu_' + slotProps.data.id" @click="fn.toggle($event, slotProps.data)" />
                                <Menu :id="'overlay_menu_' + slotProps.data.id" class="kebab-slide-out-menu" :model="itemExtraActions" :popup="false" :class="{'block': vm.visibleMenuId === slotProps.data.id }" />
                                <div v-if="vm.visibleMenuId === slotProps.data.id" class="fixed bottom-0 left-0 right-0 top-0" @click="vm.visibleMenuId = null"></div>
                            </template>
                        </Column>
                    </DataTable>
                </div>
                <Message severity="info" :closable="false" v-else>
                    Der er ingen brugere oprettet
                </Message>
            </div>
        </div>

        <Sidebar v-model:visible="vm.slideInCreate" :baseZIndex="1000" position="right" class="p-sidebar-md">
            <form @submit.prevent="fn.createOrUpdateUser(v$)" class="p-fluid h-full flex flex-column" novalidate>

                <h3 class="pb-2">
                    <span v-if="vm.isUserEdit">Rediger</span>
                    <span v-else>Opret ny bruger</span>
                </h3>
                <div class="box p-formgrid">
                    <div class="grid">
                        <div class="col-5">
                            <span class="p-float-label">
                                <InputText id="inputfirstname" type="text" class="w-full p-inputtext-lg" v-model="vm.currentItem.firstname" :class="{'p-invalid':v$.firstname.$invalid && vm.submitted}" required />
                                <label for="inputfirstname">Fornavn *</label>
                            </span>
                            <small v-if="(v$.firstname.$invalid && vm.submitted) || v$.firstname.$pending.$response" class="p-error">Fornavn skal udfyldes</small>
                        </div>
                        <div class="col-7">
                            <span class="p-float-label">
                                <InputText id="inputlastname" type="text" class="w-full p-inputtext-lg" v-model="vm.currentItem.lastname" :class="{'p-invalid':v$.lastname.$invalid && vm.submitted}" required />
                                <label for="inputlastname">Efternavn *</label>
                                <small v-if="(v$.lastname.$invalid && vm.submitted) || v$.lastname.$pending.$response" class="p-error">Efternavn skal udfyldes</small>
                            </span>
                        </div>
                        <div class="col-12">
                            <span class="p-float-label">
                                <InputText id="inputemail" type="text" class="w-full p-inputtext-lg" v-model="vm.currentItem.email" :class="{'p-invalid':v$.email.$invalid && vm.submitted}" required />
                                <label for="inputemail">Email *</label>
                                <small v-if="(v$.email.email.$invalid && vm.submitted) || v$.email.$pending.$response" class="p-error">Den indtastede e-mail er ikke gyldig</small>
                                <small v-else-if="(v$.email.$invalid && vm.submitted) || v$.email.$pending.$response" class="p-error">E-mail skal udfyldes</small>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="align-self-end mt-auto sticky bottom-spacing">
                    <Button v-if="vm.isUserEdit" label="Gem" class="p-button-success p-button-lg" type="submit" />
                    <Button v-else label="Opret" class="p-button-success p-button-lg" type="submit" />
                </div>
            </form>
            <div v-if="vm.creating" class="absolute overlay overlay-submitted">
                <ProgressSpinner />
            </div>
        </Sidebar>

        <ConfirmDialog group="confirmDelete" :style="{ maxWidth: '600px', width: '100%' }">
            <div>Er du sikker på at du vil slette <b>{{ vm.currentItem?.firstname }} {{ vm.currentItem?.lastname }}</b>?</div>
            <Message severity="warn" :closable="false">
                Når en bruger slettes, vil vedkommende ikke længere kunne logge ind.
                <br />
                Brugerens historik i hændelsesloggen vil dog blive bevaret.
            </Message>
        </ConfirmDialog>

        <ConfirmDialog group="confirmReinvite" :style="{ maxWidth: '600px', width: '100%' }">
            <div>Er du sikker på, at du vil sende en ny invitation til <b>{{ vm.currentItem?.firstname }} {{ vm.currentItem?.lastname }}</b>?</div>
        </ConfirmDialog>

        <div v-if="vm.busy" class="overlay overlay-submitting">
            <ProgressSpinner />
        </div>
    </div>
</template>

<script setup>
    import { inject, ref, reactive, computed } from 'vue';
    import { FilterMatchMode } from 'primevue/api';
    import api from '@/composables/api';
    import { useToast } from 'primevue/usetoast';
    import { useConfirm } from 'primevue/useconfirm';
    import { useVuelidate } from "@vuelidate/core";
    import { required, email } from "@vuelidate/validators";

    const apiUrl = inject('apiUrl');
    const toast = useToast();
    const confirm = useConfirm();
    const store = inject('store');

    const isCreateButtonDisabled = computed(() => vm.items.length >= store.config.maxUsers);

    const filters = ref({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const vm = reactive({
        items: [],
        busy: false,
        creating: false,
        initialized: false,
        showSecret: false,
        clientSecret: null,
        slideInCreate: false,
        visibleMenuId: null,
        currentItem: null,
        isUserEdit: false,
        submitted: false
    });

    const itemExtraActions = ref([
        {
            label: 'Se detaljer/rediger',
            icon: 'pi pi-pencil',
            command: () => {
                fn.showSidebar(vm.currentItem, true);
                vm.visibleMenuId = null;
            }
        },
        {
            label: 'Slet',
            icon: 'pi pi-trash',
            command: () => {
                confirm.require({
                    group: 'confirmDelete',
                    header: 'Bekræft slet',
                    acceptLabel: 'Ja, slet',
                    acceptClass: 'p-button-success p-button-lg',
                    rejectLabel: 'Nej, fortryd',
                    rejectClass: 'p-button-link p-button-lg',
                    accept: () => fn.delete(vm.currentItem)
                });
                vm.visibleMenuId = null;
            }
        },
        {
            label: 'Gensend invitation',
            icon: 'pi pi-envelope',
            command: () => {
                confirm.require({
                    group: 'confirmReinvite',
                    header: 'Bekræft gensendelse',
                    acceptLabel: 'Ja, gensend',
                    acceptClass: 'p-button-success p-button-lg',
                    rejectLabel: 'Nej, fortryd',
                    rejectClass: 'p-button-link p-button-lg',
                    accept: () => fn.reinvite(vm.currentItem)
                });
                vm.visibleMenuId = null;
            },
            visible: () => {
                return vm.currentItem && (vm.currentItem.status === 0 || vm.currentItem.status === 1);
            }
        }
    ]);

    const dataRules = computed(() => {
        return {
            firstname: { required },
            lastname: { required },
            email: { required, email }
        }
    });

    let v$ = null;

    const fn = {
        showSidebar(item, isUserEdit) {
            vm.submitted = false;
            vm.currentItem = item || {};
            v$ = useVuelidate(dataRules, vm.currentItem);
            vm.isUserEdit = isUserEdit;
            vm.slideInCreate = true;
        },
        createOrUpdateUser(form) {
            vm.submitted = true;

            if (form.$invalid) {
                return;
            }

            vm.creating = true;
            const endpoint = vm.currentItem.id ? `systemusers/${vm.currentItem.id}` : 'systemusers';
            const method = vm.currentItem.id ? 'patch' : 'postJson';
            api[method](apiUrl + endpoint, vm.currentItem)
                .then(response => {
                    if (!vm.currentItem.id && response) {
                        vm.currentItem = response;
                        vm.items.push(vm.currentItem);
                    }
                    vm.slideInCreate = false;
                })
                .catch(error => handleError('Der skete en fejl', error))
                .finally(() => { vm.creating = false; vm.submitted = false; });
        },
        delete(item) {
            vm.busy = true;
            api.del(`${apiUrl}systemusers/${item.id}`)
                .then(() => {
                    vm.items = vm.items.filter(obj => obj.id !== item.id);
                    toast.add({ severity: 'success', summary: 'Gennemført', detail: `${item.firstname} ${item.lastname} slettet`, life: 5000 });
                })
                .catch(error => handleError('Der skete en fejl', error))
                .finally(() => vm.busy = false);
        },
        reinvite(item) {
            vm.busy = true;
            api.postJson(`${apiUrl}systemusers/${item.id}/invitations`)
                .then(() => {
                    toast.add({ severity: 'success', summary: 'Gennemført', detail: `Invitation til ${item.firstname} ${item.lastname} gensendt`, life: 5000 });
                })
                .catch(error => handleError('Der skete en fejl', error))
                .finally(() => vm.busy = false);
        },
        toggle(event, data) {
            vm.currentItem = data;
            vm.visibleMenuId = vm.currentItem.id === vm.visibleMenuId ? null : vm.currentItem.id;
        }
    };

    function handleError(summary, error) {
        toast.add({ severity: 'error', summary, detail: error });
    }

    // Init data
    api.get(`${apiUrl}systemusers`)
        .then(response => {
            vm.items = response || [];
            vm.initialized = true;
        })
        .catch(error => handleError('Der skete en fejl', error));
</script>


